import Cookies from 'js-cookie';
import analytics from 'utils/analytics';
import BackendApiService from 'services/BackendApiService';
import ProductSheetsService from 'services/ProductSheetsService';
import * as types from './mutation-types';

export default {
  resetActionMessage({ commit }) {
    commit(types.SET_ACTION_MESSAGE, null);
  },
  showMobileMenu({ commit }) {
    commit(types.SET_MOBILE_MENU_VISIBILITY, true);
  },
  hideMobileMenu({ commit }) {
    commit(types.SET_MOBILE_MENU_VISIBILITY, false);
  },
  toggleMobileMenu({ state, dispatch }) {
    if (state.mobileMenuVisible) {
      dispatch('hideMobileMenu');
    } else {
      dispatch('showMobileMenu');
    }
  },
  showCookieModal({ commit }) {
    commit(types.SET_COOKIE_MODAL_VISIBILITY, true);
  },
  hideCookieModal({ commit }) {
    commit(types.SET_COOKIE_MODAL_VISIBILITY, false);
  },
  setCookieSettings({
    state, rootState, getters, rootGetters, commit, dispatch
  }, settings) {
    Cookies.set('ce-cookieSettings', JSON.stringify(settings), {
      expires: 30,
      secure: process.env.NODE_ENV !== 'development',
    });
    commit(types.SET_COOKIE_SETTINGS, settings);

    // Init analytics if allowed
    if (getters.functionalCookiesEnabled) {
      if (!analytics.initiated) {
        analytics.init();

        if (rootGetters['user/isPersonalizedTrackingEnabled']) {
          analytics.setUser(rootState.user.activeUser.email);
        }

        analytics.trackPageView();
      }
    } else {
      // Disable matomo cookies otherwise
      analytics.disable();
    }

    if (state.cookieModalVisible) dispatch('hideCookieModal');
  },
  trackEvent({ getters, rootGetters }, {
    category, action, name = null, value = null
  }) {
    if (getters.functionalCookiesEnabled) {
      let nameToTrack = null;
      if (name !== false) {
        if (typeof name === 'object') {
          nameToTrack = {
            ...name,
            userStatus: rootGetters['user/userStatus'],
            userId: rootGetters['user/userTrackingId'],
            company: rootGetters['user/userTrackingCompany'],
            accountManager: rootGetters['user/userTrackingAccountManager']
          };

          nameToTrack = JSON.stringify(nameToTrack);
        }
      }

      analytics.trackEvent(
        category,
        action,
        nameToTrack,
        value
      );
    }
  },
  addToOverlayStack({ commit }, uid) {
    commit(types.ADD_TO_OVERLAY_STACK, uid);
  },
  removeFromOverlayStack({ commit }, uid) {
    commit(types.REMOVE_FROM_OVERLAY_STACK, uid);
  },
  async checkForMySheetsUpdates({ commit, rootState }) {
    if (!rootState.user.activeUser) return;

    const response = await ProductSheetsService.getChangedMySheets(rootState.user.activeUser);

    if (response && response.total && response.total > 0) {
      commit(types.SET_CHANGED_MY_SHEETS, response);
    }
  },
  async fetchCountryList({ commit }) {
    const countryList = await BackendApiService.getCountryList();
    commit(types.SET_COUNTRY_LIST, countryList);
  }
};
