<template>
  <li
    class="ce-menuItem ce-menuItem--mobile"
    :class="[
      item.linkingMode,
      {'ce-menuItem--root': isRootLevel},
      {'ce-menuItem--secondary': !isRootLevel},
      {'ce-menuItem--subNavOpen': hasSubNav && subNavVisible}
    ]">
    <div class="ce-menuItemContent">
      <a
        :target="item.target"
        :href="item.link">
        {{ item.menuTitle }}
      </a>

      <a
        v-if="hasSubNav"
        class="ce-menuItem-subNavToggle"
        @click.prevent="toggleSubNav">
        <fa-icon icon="angle-down" />
      </a>
    </div>

    <transition
      :css="false"
      @enter="enterAnimation"
      @leave="leaveAnimation">
      <nav
        v-if="hasSubNav && subNavVisible"
        class="ce-secondLevelNav">
        <ul class="list-reset">
          <menu-item
            v-for="subPage in item.children"
            :key="subPage.id"
            :item="subPage"
            :is-root-level="false" />
        </ul>
      </nav>
    </transition>
  </li>
</template>

<script>
import slideAnimation from 'mixins/slideAnimation';
import MenuItem from 'mixins/MenuItem';

export default {
  mixins: [slideAnimation, MenuItem],
  name: 'MenuItem',
  methods: {
    showSubNav() {
      this.subNavVisible = true;
    },
    hideSubNav() {
      this.subNavVisible = false;
    },
    toggleSubNav() {
      if (this.subNavVisible) {
        this.hideSubNav();
      } else {
        this.showSubNav();
      }
    }
  }
};
</script>

<style lang="less">
@import (reference) "~styles/includes/base";

.ce-menuItem.ce-menuItem--mobile {
  border-bottom: 0 !important;

  .ce-menuItemContent {
    display: flex;
    justify-content: space-between;
    padding: @space-3 @space-4;
  }

  .ce-menuItem-subNavToggle {
    color: @color-mono-100;

    svg {
      transition: transform 250ms ease-in-out;
    }
  }

  .ce-secondLevelNav {
    margin-left: @space-4;
    .ms(1);
    background: @color-sc-light-green;
    padding: @space-2;
    text-shadow: none;
  }

  &.ce-menuItem--subNavOpen {
    .ce-menuItem-subNavToggle {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  &.ce-menuItem--root {
    &.current, &.section {
      > .ce-menuItemContent {
        font-weight: 700;
        .ms(4);

        a {
          color: @color-mono-100;
        }
      }
    }
  }

  &.ce-menuItem--secondary {
    &.current, &.section {
      > .ce-menuItemContent {
        a {
          color: @color-mono-0;
        }
      }
    }
  }
}
</style>
