var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ce-searchField" },
    [
      _c(
        "vue-simple-suggest",
        {
          ref: "suggestField",
          attrs: {
            list: _vm.suggest,
            "remove-list": _vm.suggestionsDisabled,
            "display-attribute": "product_name",
            debounce: 400,
            destyled: false,
            "prevent-submit": false,
          },
          on: { select: _vm.selected, "show-list": _vm.listShown },
          model: {
            value: _vm.term,
            callback: function ($$v) {
              _vm.term = $$v
            },
            expression: "term",
          },
        },
        [
          _c("input", {
            attrs: {
              placeholder: _vm.$t("searchField.placeholder"),
              type: "text",
              name: "term",
              autocomplete: "off",
              autocorrect: "off",
              autocapitalize: "off",
              spellcheck: "false",
            },
            domProps: { value: _vm.term },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.enter.apply(null, arguments)
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ms-2 mt2 pl2" },
        [
          _vm.onSearchPage
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("searchField.hintOnSerp")) +
                    "\n    "
                ),
              ]
            : [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("searchField.hintGeneric")) +
                    "\n    "
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.enter.apply(null, arguments)
            },
          },
        },
        [_c("fa-icon", { attrs: { icon: ["fas", "search"] } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }