import * as types from './mutation-types';

export default {
  [types.SET_ACTION_MESSAGE](state, actionMessage) {
    state.actionMessage = actionMessage;
  },
  [types.SET_MOBILE_MENU_VISIBILITY](state, visible) {
    state.mobileMenuVisible = visible;
  },
  [types.SET_COOKIE_MODAL_VISIBILITY](state, visible) {
    state.cookieModalVisible = visible;
  },
  [types.SET_COOKIE_SETTINGS](state, settings) {
    state.cookieSettings = settings;
  },
  [types.ADD_TO_OVERLAY_STACK](state, uid) {
    state.overlayStack.push(uid);
  },
  [types.REMOVE_FROM_OVERLAY_STACK](state, uid) {
    const index = state.overlayStack.findIndex((id) => id === uid);

    if (index > -1) {
      state.overlayStack.splice(index, 1);
    }
  },
  [types.SET_CHANGED_MY_SHEETS](state, result) {
    state.changedMySheets = result;
  },
  [types.SET_COUNTRY_LIST](state, list) {
    state.countryList = list;
  }
};
