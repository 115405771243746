var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fadeUp" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "ce-alert",
            class: [_vm.cssClass, { "ce-alert--closable": _vm.closable }],
          },
          [
            _vm.withIcon
              ? _c(
                  "div",
                  { staticClass: "ce-alert-icon mr2" },
                  [_c("fa-icon", { attrs: { icon: _vm.icon } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ce-alert-message flex-auto" },
              [
                !_vm.message
                  ? _vm._t("default")
                  : [_vm._v("\n        " + _vm._s(_vm.message) + "\n      ")],
              ],
              2
            ),
            _vm._v(" "),
            _vm.closable
              ? _c(
                  "div",
                  {
                    staticClass: "ce-alert-closeCta",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.close.apply(null, arguments)
                      },
                    },
                  },
                  [_c("fa-icon", { attrs: { icon: ["fas", "times"] } })],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }