import { translate } from 'utils/i18n';

const navigationSections = {
  settings: 'settings',
  data: 'data'
};

const routes = [
  {
    path: '/',
    name: 'home',
    showInNavigation: false,
    meta: {
      title: translate('userProfile.routes.home')
    },
    beforeEnter: (to, from, next) => {
      next({ name: 'personalInformation' });
    }
  },
  {
    path: '/personal',
    name: 'personalInformation',
    navigationSection: navigationSections.settings,
    meta: {
      title: translate('userProfile.routes.personalInformation'),
      icon: ['fas', 'user']
    },
    component: () => import(/* webpackChunkName: "userProfile-personalInformation" */ 'components/user-profile/PersonalInformation')
  },
  {
    path: '/privacy',
    name: 'privacy',
    navigationSection: navigationSections.settings,
    meta: {
      title: translate('userProfile.routes.privacy'),
      icon: ['fas', 'user-secret']
    },
    component: () => import(/* webpackChunkName: "userProfile-privacy" */ 'components/user-profile/PrivacySettings')
  },
  {
    path: '/status',
    name: 'userStatus',
    navigationSection: navigationSections.settings,
    meta: {
      title: translate('userProfile.routes.userStatus'),
      icon: ['fas', 'signature']
    },
    component: () => import(/* webpackChunkName: "userProfile-status" */ 'components/user-profile/UserStatus')
  },
  {
    path: '/notifications',
    name: 'notifications',
    navigationSection: navigationSections.settings,
    meta: {
      title: translate('userProfile.routes.notifications'),
      icon: ['far', 'envelope']
    },
    component: () => import(/* webpackChunkName: "userProfile-notifications" */ 'components/user-profile/NotificationSettings')
  },
  {
    path: '/sample-requests',
    name: 'sampleRequests',
    navigationSection: navigationSections.data,
    meta: {
      title: translate('userProfile.routes.sampleRequests'),
      icon: ['fas', 'vial']
    },
    component: () => import(/* webpackChunkName: "userProfile-sampleRequests" */ 'components/user-profile/SampleRequests')
  },
  {
    path: '/product-comparisons',
    name: 'productComparisons',
    navigationSection: navigationSections.data,
    meta: {
      title: translate('userProfile.routes.productComparisons'),
      icon: ['fas', 'list']
    },
    component: () => import(/* webpackChunkName: "userProfile-productComparisons" */ 'components/user-profile/ProductComparisons')
  }
];

export { routes, navigationSections };
