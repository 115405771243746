var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "ce-mainNav" }, [
    _c(
      "ul",
      { staticClass: "list-reset" },
      _vm._l(_vm.menuItems, function (rootPage) {
        return _c("menu-item", {
          key: rootPage.id,
          attrs: { item: rootPage, "top-offset": _vm.subNavTopOffset },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }