import { mapGetters, mapState } from 'vuex';
import Alert from 'components/Alert';

export default {
  props: {
    /**
     * Additional data passed from outside which can be used during submit
     */
    additionalData: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      isSending: false,
      message: {},
    };
  },
  components: { Alert },
  computed: {
    ...mapState('user', {
      user: 'activeUser'
    }),
    ...mapGetters('user', ['isValidated']),
    ...mapState('generic', ['contactRegions', 'formSecurityToken', 'links']),
    consentHintNecessary() {
      return !(this.user
        && this.user.consent
        && Array.isArray(this.user.consent)
        && this.user.consent.indexOf('inquiries') > -1);
    },
    hasMessage() {
      return this.message && this.message.content;
    },
    selectedRegion() {
      if (this.form.region) {
        return this.contactRegions.find((r) => r.code === this.form.region);
      }

      return null;
    },
    selectedSubRegion() {
      if (this.selectedRegion
        && this.selectedRegion.subRegions
        && this.form.subRegion) {
        return this.selectedRegion.subRegions.find((r) => r.code === this.form.subRegion);
      }

      return null;
    },
    fullRegionLabel() {
      if (!this.regionSelectionNecessary || !this.selectedRegion) return null;

      let label = this.selectedRegion.title;

      if (this.selectedSubRegion) {
        label += ` :: ${this.selectedSubRegion.title}`;
      }

      return label;
    }
  }
};
