<template>
  <div class="ce-userLogin-content">
    <div
      v-if="verificationEmailSent"
      class="line-height-3">
      {{ $t('login.verificationEmail.sentMessage') }}
    </div>
    <template v-else>
      <alert
        v-if="hasMessage"
        :type="message.type"
        :closable="false">
        <div>
          <p class="my0">
            {{ message.content }}

            <i18n
              v-if="message.code && message.code === 'email_not_verified'"
              path="login.verificationEmail.sendNewMessage"
              tag="span">
              <a
                href=""
                @click.prevent="resendVerificationEmail">
                {{ $t('login.verificationEmail.sendNewCta') }}
              </a>
            </i18n>
          </p>
        </div>
      </alert>

      <template v-if="!twoFactorAuthActive">
        <ValidationObserver
          ref="validationObserver"
          tag="div">
          <validated-input
            ref="emailInputField"
            name="email"
            :value="form.email"
            @input="onInput"
            :label="$t('login.email')"
            autocapitalize="none"
            rules="required|email" />

          <validated-input
            name="password"
            :value="form.password"
            @input="onInput"
            :label="$t('login.password')"
            rules="required"
            type="password"
            @keydown.enter="submit" />

          <div>
            <button
              class="ce-btn ce-btn-light ce-btn--fullWidth"
              :disabled="loading"
              @click.prevent="submit">
              <template v-if="loading">
                <fa-icon
                  icon="spinner"
                  spin />
              </template>
              <template v-else>
                {{ $t('login.loginCta') }}
              </template>
            </button>
          </div>
        </ValidationObserver>

        <div
          class="mt2 ce-userLogin-subActions"
          v-if="hasSubActions">
          <div
            class="ce-userLogin-register"
            v-if="showRegisterCta">
            {{ $t('login.noAccount') }} <a
              :href="registerLink"
              @click.prevent="$store.dispatch('user/showRegistration')">{{ $t('login.registerCta') }}</a>
          </div>

          <div v-if="showForgotPasswordCta">
            <a
              :href="loginLink"
              @click.prevent="$store.dispatch('user/showForgotPassword')">
              {{ $t('login.forgotPasswordCta') }}
            </a>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { removeUrlParam } from '../utils/url';

export default {
  data() {
    return {
      form: {
        email: null,
        password: null
      },
      loading: false,
      message: {
        type: 'success',
        content: null,
        code: null
      },
      verificationEmailSent: false,
      twoFactorAuthActive: false,
    };
  },
  props: {
    showRegisterCta: {
      type: Boolean,
      default: true,
    },
    showForgotPasswordCta: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapState('generic', ['services']),
    hasMessage() {
      return this.message && this.message.content;
    },
    registerLink() {
      return `${location.origin}${location.pathname}?showRegistration=1`;
    },
    loginLink() {
      return `${location.origin}${location.pathname}?showLogin=1`;
    },
    hasSubActions() {
      return this.showRegisterCta || this.showForgotPasswordCta;
    }
  },
  mounted() {
    try {
      // Check if there is a message in the session storage (used e.g. for the password reset logic)
      if (sessionStorage.getItem('ce-loginMessage')) {
        this.message = JSON.parse(sessionStorage.getItem('ce-loginMessage'));
        sessionStorage.removeItem('ce-loginMessage');
      }
    } catch (e) {
      // noop
    }

    if (payload?.twoFactorAuthActive) {
      removeUrlParam('state');
      this.twoFactorAuthActive = true;
    }

    if (payload?.loginMessage) {
      this.message = {
        type: payload.loginMessage.type,
        content: payload.loginMessage.content
      };
    }
  },
  watch: {
    twoFactorAuthActive() {
      if (this.twoFactorAuthActive) {
        this.message = {
          type: 'success',
          content: this.$t('login.twoFactorAuth.message')
        };
      } else {
        this.message = {
          type: 'success',
          content: null,
          code: null
        };
      }
    }
  },
  methods: {
    async submit() {
      const validationResult = await this.$refs.validationObserver.validate();

      if (!validationResult) return;
      this.loading = true;
      this.message = {};

      try {
        this.$trackEvent('user', 'login', false);
        const loginResponse = await this.$store.dispatch('user/login', {
          email: this.form.email,
          password: this.form.password
        });
        this.loading = false;

        if (loginResponse?.token) {
          if (payload?.loginBackUrl) {
            location.href = payload.loginBackUrl;
          } else if (payload?.pageStatus === 403) {
            location.reload();
          } else {
            // Trigger the check for changed MySheets documents
            this.$store.dispatch('generic/checkForMySheetsUpdates');
          }
        } else if (loginResponse?.status === '2fa') {
          this.twoFactorAuthActive = true;
        }
      } catch (e) {
        let message = this.$t('login.error.generic');
        let code = null;
        if (e.response?.data?.message) {
          code = e.response.data.message;
          const customMessage = this.$t(`login.error.${e.response.data.message}`);
          if (customMessage && customMessage !== `login.error.${e.response.data.message}`) {
            message = customMessage;
          }
        }
        this.message = {
          type: 'error',
          content: message,
          code
        };
        this.loading = false;
      }
    },
    async resendVerificationEmail() {
      const result = await this.$refs.emailInputField.validate();
      if (!result.valid) return;

      try {
        this.$trackEvent('user', 'resend_verification_email', false);
        await this.$store.dispatch('user/resendVerificationEmail', this.form.email);
        this.verificationEmailSent = true;
      } catch (e) {
        this.message = {
          type: 'error',
          content: this.$t('login.error.generic')
        };
      }
    },
  }
};
</script>

<style lang="less">
  @import (reference) '~styles/includes/base';

  .ce-userLogin-content {
    > div {
      .mb3;
    }

    .ce-userLogin-subActions {
      .ce-pageHeader .ce-pageHeader-main & {
        a {
          color: @color-light;
        }
      }

      @media screen and (min-width: @breakpoint-xs) {
        display: flex;
        justify-content: space-between;
      }

    }
  }
</style>
