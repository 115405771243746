var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ce-changedProductRow",
      class: { "ce-changedProductRow--open": _vm.isOpen },
    },
    [
      _c(
        "div",
        {
          staticClass: "ce-changedProductRow-header",
          on: {
            click: function ($event) {
              _vm.isOpen = !_vm.isOpen
            },
          },
        },
        [
          _c("div", { staticClass: "ce-changedProductRow-toggle" }, [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "angle-down" } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      " + _vm._s(_vm.product.product_name) + "\n    "),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { css: false },
          on: { enter: _vm.enterAnimation, leave: _vm.leaveAnimation },
        },
        [
          _vm.isOpen
            ? _c(
                "div",
                { staticClass: "ce-changedProductRow-assets" },
                _vm._l(_vm.product.assets, function (asset) {
                  return _c("div", { key: asset.id }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.manifest[asset.type]) +
                        " v." +
                        _vm._s(asset.version) +
                        "\n      "
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }