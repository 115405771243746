export default {
  functionalCookiesEnabled(state) {
    return state.cookieSettings.functional === true;
  },
  marketingCookiesEnabled(state) {
    return state.cookieSettings.marketing === true;
  },
  userManagementLink(state, getters, rootState) {
    if (state.links
      && state.links.userManagement
      && rootState.user.activeUser
      && rootState.user.activeUser.token) {
      return `${state.links.userManagement}?token=${rootState.user.activeUser.token}`;
    }

    return null;
  },
  isFloatingActionVisible(state, getters, rootState, rootGetters) {
    if (rootState.basket && Array.isArray(rootState.basket) && rootState.basket.length > 0) {
      return true;
    }

    return rootGetters['productComparison/isBasketVisible'];
  }
};
