var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ValidationObserver",
    {
      ref: "validationObserver",
      staticClass: "ce-modalContent",
      attrs: { tag: "div" },
    },
    [
      _vm.hasMessage
        ? _c("alert", {
            staticClass: "mb3",
            attrs: { message: _vm.message.content, type: _vm.message.type },
            on: {
              close: function ($event) {
                _vm.message = {}
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ce-contactForm-userInfo" },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap" },
            [
              _c("validated-input", {
                staticClass: "col-12 xs-col-6 xs-pr3",
                attrs: {
                  name: "firstName",
                  value:
                    _vm.user && _vm.user.firstName
                      ? _vm.user.firstName
                      : _vm.form.firstName,
                  disabled: !!(_vm.user && _vm.user.firstName),
                  label: _vm.$t("contactForm.firstName"),
                  rules: "required",
                },
                on: { input: _vm.onInput },
              }),
              _vm._v(" "),
              _c("validated-input", {
                staticClass: "col-12 xs-col-6 xs-pl3",
                attrs: {
                  name: "lastName",
                  value:
                    _vm.user && _vm.user.lastName
                      ? _vm.user.lastName
                      : _vm.form.lastName,
                  disabled: !!(_vm.user && _vm.user.lastName),
                  label: _vm.$t("contactForm.lastName"),
                  rules: "required",
                },
                on: { input: _vm.onInput },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("validated-input", {
            attrs: {
              name: "email",
              value:
                _vm.user && _vm.user.email ? _vm.user.email : _vm.form.email,
              disabled: !!(_vm.user && _vm.user.email),
              label: _vm.$t("contactForm.email"),
              rules: "required|email",
            },
            on: { input: _vm.onInput },
          }),
          _vm._v(" "),
          _c("validated-input", {
            attrs: {
              name: "company",
              value:
                _vm.user && _vm.user.company
                  ? _vm.user.company
                  : _vm.form.company,
              disabled: !!(_vm.user && _vm.user.company),
              label: _vm.$t("contactForm.company"),
              rules: "required",
            },
            on: { input: _vm.onInput },
          }),
          _vm._v(" "),
          _vm.countrySelectionNecessary
            ? _c("country-select", {
                attrs: {
                  name: "country",
                  value:
                    _vm.user && _vm.user.country
                      ? _vm.user.country
                      : _vm.form.country,
                  disabled: !!(_vm.user && _vm.user.country),
                  label: _vm.$t("countrySelect.label"),
                  rules: "required",
                },
                on: {
                  input: function ($event) {
                    _vm.form.country = $event
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.industrySelectionNecessary
            ? _c("validated-select", {
                attrs: {
                  name: "industry",
                  label: _vm.$t("contactForm.industry"),
                  options: _vm.industryOptions,
                  value: _vm.form.industry,
                  rules: "required",
                  "show-other-option": "",
                },
                on: {
                  input: function ($event) {
                    _vm.form.industry = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasTopics
        ? _c("validated-select", {
            attrs: {
              value: _vm.selectedTopic.id,
              name: "topic",
              label: "Topics",
              options: _vm.topicSelectOptions,
            },
            on: {
              input: function ($event) {
                return _vm.selectTopic($event)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.regionSelectionNecessary
            ? _c("ValidationProvider", {
                staticClass: "ce-field",
                attrs: {
                  name: _vm.$t("contactForm.region"),
                  rules: "required",
                  mode: "eager",
                  tag: "div",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("contactForm.region")) +
                                " "
                            ),
                            _c("span", [_vm._v("*")]),
                            _vm._v(" "),
                            errors.length > 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ms-2 ml1 regular ce-colorError",
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(errors[0]) +
                                        "\n        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.region,
                                  expression: "form.region",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.form,
                                    "region",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.contactRegions, function (region) {
                              return _c(
                                "option",
                                {
                                  key: region.code,
                                  domProps: { value: region.code },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(region.title) +
                                      "\n        "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  582172998
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.regionSelectionNecessary &&
          _vm.selectedRegion &&
          _vm.selectedRegion.subRegions
            ? _c("ValidationProvider", {
                staticClass: "ce-field",
                attrs: {
                  name: _vm.$t("contactForm.subRegion"),
                  rules: "required",
                  mode: "eager",
                  tag: "div",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("contactForm.subRegion")) +
                                " "
                            ),
                            _c("span", [_vm._v("*")]),
                            _vm._v(" "),
                            errors.length > 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ms-2 ml1 regular ce-colorError",
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(errors[0]) +
                                        "\n        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.subRegion,
                                  expression: "form.subRegion",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.form,
                                    "subRegion",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(
                              _vm.selectedRegion.subRegions,
                              function (subRegion) {
                                return _c(
                                  "option",
                                  {
                                    key: subRegion.code,
                                    domProps: { value: subRegion.code },
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(subRegion.title) +
                                        "\n        "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2010243741
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("validated-input", {
            attrs: {
              name: "message",
              value: _vm.form.message,
              type: "textarea",
              label: _vm.$t("contactForm.message"),
              rules: "required",
            },
            on: { input: _vm.onInput },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.consentHintNecessary
        ? _c(
            "div",
            { staticClass: "ms-1 line-height-4" },
            [
              _c(
                "i18n",
                { attrs: { path: "contactForm.consentHint", tag: "p" } },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.links.dataProtectionPage,
                        rel: "noopener noreferrer",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("contactForm.dataProtectionPage"))
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mt4" }, [
        _c(
          "button",
          {
            staticClass: "ce-btn ce-btn-light ce-btn--fullWidth",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _vm.isSending
              ? [_c("fa-icon", { attrs: { icon: "spinner", spin: "" } })]
              : [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("contactForm.submitCta")) +
                      "\n      "
                  ),
                ],
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }