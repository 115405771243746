<template>
  <validated-select
    :name="name"
    :label="label"
    :options="options"
    :value="value"
    @input="$emit('input', $event)"
    :rules="rules"
    :disabled="disabled"
    searchable
    :loading="countriesLoading" />
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: String,
      required: false,
      default: null
    }
  },
  created() {
    if (!this.countryList) {
      this.fetchCountryList();
    }

    if (!this.value && this.requestCountry) {
      this.$emit('input', this.requestCountry);
    }
  },
  computed: {
    ...mapState('generic', ['requestCountry', 'countryList']),
    options() {
      return this.countryList ?? {};
    },
    countriesLoading() {
      return !this.countryList;
    }
  },
  methods: {
    ...mapActions('generic', ['fetchCountryList']),
    async validate() {
      return this.$refs.validationProvider.validate();
    }
  }
};
</script>
