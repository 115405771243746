<template>
  <span>
    <slot />
  </span>
</template>

<script>
import Tooltip from 'tooltip.js';

export default {
  data() {
    return {
      tooltip: null
    };
  },
  props: {
    content: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: 'top'
    },
    delay: {
      type: [Number, Object],
      required: false,
      default() {
        return { show: 400, hide: 0 };
      }
    },
    trigger: {
      type: String,
      required: false,
      default: 'hover'
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    content(newValue) {
      this.tooltip.updateTitleContent(newValue);
    }
  },
  methods: {
    init() {
      const options = {
        trigger: this.trigger,
        html: true,
        title: this.content,
        placement: this.placement,
        closeOnClickOutside: true,
        delay: this.delay
      };

      this.tooltip = new Tooltip(this.$el, options);
    }
  }
};
</script>

<style lang="less">
  @import (reference) '~styles/includes/base';
  @backgroundColor: @color-light;

  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-style: normal;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    word-wrap: break-word;
    .ms(-1);
    font-weight: 700;

    .tooltip-arrow {
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.4rem;

      &::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
      }
    }

    .tooltip-inner {
      max-width: 300px;
      padding: @space-2;
      color: @color-mono-100;
      background-color: @backgroundColor;
      border-radius: @border-radius;
    }
  }

  .tooltip[x-placement^="top"] {
    padding: 0.4rem 0;

    .tooltip-arrow {
      bottom: 0;

      &::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: @backgroundColor;
      }
    }
  }

  .tooltip[x-placement^="right"] {
    padding: 0 0.4rem;

    .tooltip-arrow {
      left: 0;
      width: 0.4rem;
      height: 0.8rem;

      &::before {
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: @backgroundColor;
      }
    }
  }

  .tooltip[x-placement^="bottom"] {
    padding: 0.4rem 0;

    .tooltip-arrow {
      top: 0;

      &::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: @backgroundColor;
      }
    }
  }

  .tooltip[x-placement^="left"] {
    padding: 0 0.4rem;

    .tooltip-arrow {
      right: 0;
      width: 0.4rem;
      height: 0.8rem;

      &::before {
        left: 0;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-left-color: @backgroundColor;
      }
    }
  }
</style>
