<template>
  <div
    class="ce-field ce-selectField"
    :class="{'ce-selectField--withOtherOption': otherOptionActive}">
    <ValidationProvider
      :name="validatorName"
      v-slot="{ errors }"
      :rules="rules"
      :mode="mode"
      tag="div">
      <label
        v-if="label"
        :for="name">
        {{ label }} <span v-if="isRequired && showRequiredIndicator">*</span>
        <span
          v-if="errors.length > 0"
          class="ms-2 ml1 regular ce-colorError">
          {{ errors[0] }}
        </span>
      </label>

      <div class="ce-selectField-fieldWrapper">
        <div>
          <multiselect
            :id="name"
            :name="name"
            :value="preparedValue"
            :options="preparedOptions"
            label="title"
            track-by="value"
            :searchable="searchable"
            :close-on-select="true"
            :allow-empty="showEmptyDefault"
            placeholder=""
            @input="handleInput"
            :disabled="disabled"
            :loading="loading" />
        </div>
      </div>
    </ValidationProvider>

    <ValidationProvider
      v-if="otherOptionActive"
      :name="validatorName"
      v-slot="{ errors }"
      :rules="otherRules"
      :mode="mode"
      tag="div">
      <label v-if="label">
        {{ $t('generic.otherOption') }} <span v-if="isOtherRequired && showRequiredIndicator">*</span>
        <span
          v-if="errors.length > 0"
          class="ms-2 ml1 regular ce-colorError">
          {{ errors[0] }}
        </span>
      </label>
      <input
        type="text"
        :disabled="disabled"
        :value="other"
        @input="handleCustomInput($event.target.value)"
        autocomplete=""
        autocapitalize="">
    </ValidationProvider>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  props: {
    value: {
      // eslint-disable-next-line no-bitwise
      type: String | Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      required: true
    },

    showEmptyDefault: {
      type: Boolean,
      required: false,
      default: false
    },

    validatorName: {
      type: String,
      required: false,
      default() {
        return this.label;
      }
    },

    rules: {
      type: String,
      required: false,
      default: null
    },

    mode: {
      type: String,
      required: false,
      default: 'eager'
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    showRequiredIndicator: {
      type: Boolean,
      required: false,
      default: true
    },

    showOtherOption: {
      type: Boolean,
      required: false,
      default: false
    },

    forceRequiredForOtherOption: {
      type: Boolean,
      required: false,
      default: false
    },

    useKeyOnlyValue: {
      type: Boolean,
      required: false,
      default: true,
    },

    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      other: null,
    };
  },
  components: { Multiselect },
  computed: {
    isRequired() {
      return this.rules && this.rules.indexOf('required') !== -1;
    },
    isOtherRequired() {
      return this.otherRules && this.otherRules.indexOf('required') !== -1;
    },
    shouldEmitFullValue() {
      return !this.useKeyOnlyValue || this.showOtherOption;
    },
    preparedOptions() {
      const options = Object.keys(this.options).map((value) => ({ title: this.options[value], value }));

      if (this.showOtherOption) {
        options.push({ title: this.$t('generic.otherOption'), value: 'other' });
      }

      return options;
    },
    preparedValue() {
      if (this.value) {
        if (typeof this.value === 'object') {
          return this.value;
        }

        if (this.options[this.value]) {
          return {
            title: this.options[this.value],
            value: this.value
          };
        }
      }

      return null;
    },
    otherOptionActive() {
      return this.showOtherOption
          && this.value
          && typeof this.value === 'object'
          && this.value.value === 'other';
    },
    otherRules() {
      if (this.forceRequiredForOtherOption && !this.isRequired) {
        return this.rules ? `${this.rules} required` : 'required';
      }

      return this.rules;
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleInput(event) {
      if (this.shouldEmitFullValue) {
        const payload = {
          ...event,
        };

        if (this.showOtherOption && payload.value === 'other') {
          payload.customInput = this.other;
        }

        this.$emit('input', payload);
      } else {
        this.$emit('input', event && event.value ? event.value : null);
      }
    },
    handleCustomInput(value) {
      console.log('handle custom input');
      this.other = value;
      this.$emit('input', {
        ...this.value,
        customInput: value,
      });
    }
  }
};
</script>

<style lang="less">
@import (reference) '~styles/includes/base';

.ce-field.ce-selectField {
  .multiselect {
    .multiselect__tags {
      .multiselect__single {
        background: none;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &.ce-selectField--withOtherOption {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    > div {
      width: 100%;

      @media screen and (min-width: @breakpoint-xs) {
        &:first-child {
          width: 70%;
          padding-right: @space-2;
        }

        &:last-child {
          width: 30%;
          padding-left: @space-2;
        }
      }

      .ce-field {
        margin-bottom: 0;
      }
    }
  }
}
</style>
