import Noty from 'noty';
import 'styles/includes/noty';
import { icon as faIcon } from '@fortawesome/fontawesome-svg-core';

Noty.setMaxVisible(1, 'saveMessages');

class Notification {
  constructor() {
    this.defaultOptions = {
      // relax, mint, metroui
      theme: 'mint',
      // top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight
      layout: 'bottomCenter',
      timeout: 10000,
      progressBar: true,
      animation: {
        speed: 250
      }
    };
  }

  static addIconToText(text, iconName, prefix = 'fas') {
    const icon = faIcon({ prefix, iconName });
    return `<span class="ce-notyIcon">${icon.html}</span> ${text}`;
  }

  show(text, type, options = {}, triggerShow = true) {
    const mergedOptions = {
      text,
      type,
      ...this.defaultOptions,
      ...options
    };

    if (mergedOptions.queue && mergedOptions.queue === 'saveMessages') {
      mergedOptions.killer = 'saveMessages';
    }

    const noty = new Noty(mergedOptions);

    if (triggerShow) noty.show();

    return noty;
  }

  info(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'exclamation'),
      'info',
      options,
      triggerShow
    );
  }

  success(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'check'),
      'success',
      options,
      triggerShow
    );
  }

  warning(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'exclamation-triangle'),
      'warning',
      options,
      triggerShow
    );
  }

  error(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'exclamation-triangle'),
      'error',
      {
        ...this.defaultOptions,
        timeout: false,
        ...options
      },
      triggerShow
    );
  }
}

export default new Notification();
