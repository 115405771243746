<template>
  <ValidationObserver
    ref="validationObserver"
    tag="div"
    class="ce-modalContent">
    <alert
      class="mb3"
      v-if="hasMessage"
      :message="message.content"
      :type="message.type"
      @close="message = {}" />

    <div
      class="ce-contactForm-userInfo">
      <div class="flex flex-wrap">
        <validated-input
          class="col-12 xs-col-6 xs-pr3"
          name="firstName"
          :value="user && user.firstName ? user.firstName : form.firstName"
          :disabled="!!(user && user.firstName)"
          @input="onInput"
          :label="$t('contactForm.firstName')"
          rules="required" />

        <validated-input
          class="col-12 xs-col-6 xs-pl3"
          name="lastName"
          :value="user && user.lastName ? user.lastName : form.lastName"
          :disabled="!!(user && user.lastName)"
          @input="onInput"
          :label="$t('contactForm.lastName')"
          rules="required" />
      </div>

      <validated-input
        name="email"
        :value="user && user.email ? user.email : form.email"
        :disabled="!!(user && user.email)"
        @input="onInput"
        :label="$t('contactForm.email')"
        rules="required|email" />

      <validated-input
        name="company"
        :value="user && user.company ? user.company : form.company"
        :disabled="!!(user && user.company)"
        @input="onInput"
        :label="$t('contactForm.company')"
        rules="required" />

      <country-select
        v-if="countrySelectionNecessary"
        name="country"
        :value="user && user.country ? user.country : form.country"
        :disabled="!!(user && user.country)"
        @input="form.country = $event"
        :label="$t('countrySelect.label')"
        rules="required" />

      <validated-select
        v-if="industrySelectionNecessary"
        name="industry"
        :label="$t('contactForm.industry')"
        :options="industryOptions"
        :value="form.industry"
        @input="form.industry = $event"
        rules="required"
        show-other-option />
    </div>

    <validated-select
      v-if="hasTopics"
      :value="selectedTopic.id"
      @input="selectTopic($event)"
      :name="'topic'"
      :label="'Topics'"
      :options="topicSelectOptions" />

    <div>
      <ValidationProvider
        v-if="regionSelectionNecessary"
        :name="$t('contactForm.region')"
        v-slot="{ errors }"
        rules="required"
        mode="eager"
        tag="div"
        class="ce-field">
        <label>
          {{ $t('contactForm.region') }} <span>*</span>
          <span
            v-if="errors.length > 0"
            class="ms-2 ml1 regular ce-colorError">
            {{ errors[0] }}
          </span>
        </label>
        <select
          v-model="form.region">
          <option
            v-for="region in contactRegions"
            :key="region.code"
            :value="region.code">
            {{ region.title }}
          </option>
        </select>
      </ValidationProvider>

      <ValidationProvider
        v-if="regionSelectionNecessary && selectedRegion && selectedRegion.subRegions"
        :name="$t('contactForm.subRegion')"
        v-slot="{ errors }"
        rules="required"
        mode="eager"
        tag="div"
        class="ce-field">
        <label>
          {{ $t('contactForm.subRegion') }} <span>*</span>
          <span
            v-if="errors.length > 0"
            class="ms-2 ml1 regular ce-colorError">
            {{ errors[0] }}
          </span>
        </label>
        <select
          v-model="form.subRegion">
          <option
            v-for="subRegion in selectedRegion.subRegions"
            :key="subRegion.code"
            :value="subRegion.code">
            {{ subRegion.title }}
          </option>
        </select>
      </ValidationProvider>

      <validated-input
        name="message"
        :value="form.message"
        @input="onInput"
        type="textarea"
        :label="$t('contactForm.message')"
        rules="required"
      />
    </div>

    <div
      v-if="consentHintNecessary"
      class="ms-1 line-height-4">
      <i18n
        path="contactForm.consentHint"
        tag="p">
        <a
          :href="links.dataProtectionPage"
          rel="noopener noreferrer">
          {{ $t('contactForm.dataProtectionPage') }}</a>
      </i18n>
    </div>

    <div class="mt4">
      <button
        class="ce-btn ce-btn-light ce-btn--fullWidth"
        @click.prevent="submit">
        <template v-if="isSending">
          <fa-icon
            icon="spinner"
            spin />
        </template>
        <template v-else>
          {{ $t('contactForm.submitCta') }}
        </template>
      </button>
    </div>
  </ValidationObserver>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import UserForm from 'mixins/UserForm';

export default {
  props: {
    /**
     * One of the available contact types:
     * - bce
     * - psr
     * - account_manager
     * - customer_service
     * - data_protection_officer
     * - contact_block ("standard" contact form)
     */
    contactType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
        company: null,
        email: null,
        region: null,
        subRegion: null,
        country: null,
        industry: null,
        message: null
      },
      selectedTopic: null
    };
  },
  mixins: [UserForm],
  created() {
    if (this.hasTopics) {
      [this.selectedTopic] = this.topics;
    }
  },
  computed: {
    ...mapState('generic', ['industries']),
    userDataNecessary() {
      if (!this.user) return true;

      let isNecessary = false;
      ['firstName', 'lastName', 'company', 'email'].forEach((mandatoryField) => {
        if (typeof this.user[mandatoryField] === 'undefined'
            || !this.user[mandatoryField]) {
          isNecessary = true;
        }
      });

      return isNecessary;
    },
    regionSelectionNecessary() {
      return this.contactType === 'bce' && (!this.user || !this.user.region);
    },
    countrySelectionNecessary() {
      return ['bce', 'contact_block', 'psr', 'account_manager', 'customer_service']
        .includes(this.contactType);
    },
    industrySelectionNecessary() {
      return ['bce', 'contact_block'].includes(this.contactType);
    },
    industryOptions() {
      return Object.fromEntries(this.industries.map((r) => [r.code, r.title]));
    },
    topics() {
      if (this.additionalData
          && this.additionalData.hasOwnProperty('topics')
          && Array.isArray(this.additionalData.topics)
          && this.additionalData.topics.length > 0) {
        return this.additionalData.topics;
      }

      return null;
    },
    hasTopics() {
      return this.contactType === 'contact_block' && this.topics !== null;
    },
    topicSelectOptions() {
      const options = {};
      this.topics.forEach((topic) => {
        options[topic.id] = topic.title;
      });

      return options;
    }
  },
  methods: {
    reset() {
      this.isSending = false;
      this.form = {
        firstName: null,
        lastName: null,
        company: null,
        country: null,
        email: null,
        region: null,
        subRegion: null,
        industry: null,
        message: null
      };
      if (this.$refs.validationObserver) {
        this.$refs.validationObserver.reset();
      }
    },
    async submit() {
      const validationResult = await this.$refs.validationObserver.validate();
      if (!validationResult) return;
      this.isSending = true;

      const data = {
        message: this.form.message,
        ...this.additionalData,
        formSecurityToken: this.formSecurityToken,
        contactType: this.contactType
      };

      ['firstName', 'lastName', 'company', 'country', 'email', 'region', 'subRegion'].forEach((f) => {
        data[f] = this.user && this.user[f] ? this.user[f] : this.form[f];
      });

      if (this.form.industry) {
        data.industry = this.form.industry;
      }

      if (this.isValidated) {
        if (this.contactType === 'customer_service') {
          data.recipient = this.user.customerService.email;
        } else if (this.contactType === 'account_manager') {
          data.recipient = this.user.accountManager.email;
        }
      }

      if (this.hasTopics && this.selectedTopic) {
        data.recipient = this.selectedTopic.recipient;
      }

      try {
        await axios.post(`${location.origin}/api/v1/message/contactForm`, data);
        this.$emit('finished');

        this.$trackEvent(
          'contact-form',
          this.contactType,
          {
            region: this.fullRegionLabel
          }
        );

        this.reset();

        // Show a success message if not rendered inside a modal
        if (this.$parent.$options.name !== 'modal') {
          this.message = {
            type: 'success',
            content: this.$t('contactForm.finishedMessage')
          };
        }
      } catch (e) {
        this.message = {
          type: 'error',
          content: e.response?.data?.message ?? e.message
        };
      }

      this.isSending = false;
    },
    selectTopic(topicId) {
      topicId = parseInt(topicId);
      this.selectedTopic = this.topics.find((topic) => topic.id === topicId);
    }
  }
};
</script>
