var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationObserver", {
    ref: "passwordValidationObserver",
    staticClass: "ce-passwordField",
    attrs: { tag: "div" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors: observerErrors }) {
          return [
            _c("ValidationProvider", {
              staticClass: "ce-field",
              attrs: {
                name: _vm.validatorName,
                rules: "required|password",
                mode: "eager",
                tag: "div",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _vm.label
                          ? _c("label", { attrs: { for: _vm.name } }, [
                              _vm._v("\n      " + _vm._s(_vm.label) + " "),
                              _vm.showRequiredIndicator
                                ? _c("span", [_vm._v("*")])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ce-field-inputWrapper",
                            class: { invalid: errors.length > 0 },
                          },
                          [
                            _c(
                              "input",
                              _vm._g(
                                {
                                  ref: "input",
                                  attrs: {
                                    id: _vm.name,
                                    type: "password",
                                    name: _vm.name,
                                    disabled: _vm.disabled,
                                    autocomplete: "off",
                                    autocapitalize: "off",
                                  },
                                  domProps: { value: _vm.value },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$emit("input", $event)
                                    },
                                  },
                                },
                                _vm.$listeners
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _vm._v(" "),
            _c("ValidationProvider", {
              staticClass: "ce-field",
              attrs: {
                rules: `required|passwordconfirmation:@${_vm.validatorName}`,
                name: _vm.$t("validation.password.confirmationLabel", {
                  fieldLabel: _vm.validatorName,
                }),
                mode: "aggressive",
                tag: "div",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _vm.label
                          ? _c("label", { attrs: { for: _vm.name } }, [
                              _vm._v(
                                "\n      " +
                                  _vm._s(
                                    _vm.$t(
                                      "validation.password.confirmationLabel",
                                      { fieldLabel: _vm.label }
                                    )
                                  ) +
                                  " "
                              ),
                              _vm.showRequiredIndicator
                                ? _c("span", [_vm._v("*")])
                                : _vm._e(),
                              _vm._v(" "),
                              errors.length > 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "ms-2 ml1 regular ce-colorError",
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(errors[0]) +
                                          "\n      "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ce-field-inputWrapper",
                            class: { invalid: errors.length > 0 },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.confirmation,
                                  expression: "confirmation",
                                },
                              ],
                              attrs: {
                                id: `${_vm.name}_confirmation`,
                                type: "password",
                                name: `${_vm.name}_confirmation`,
                                disabled: _vm.disabled,
                                autocomplete: "off",
                                autocapitalize: "off",
                              },
                              domProps: { value: _vm.confirmation },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.confirmation = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ce-passwordField-description",
                class: {
                  "ce-colorError":
                    observerErrors[_vm.validatorName] &&
                    Array.isArray(observerErrors[_vm.validatorName]) &&
                    observerErrors[_vm.validatorName].length > 0,
                },
              },
              [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("validation.password.criteriaMessage")) +
                    "\n  "
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }