import Vue from 'vue';
import * as types from './mutation-types';

export default {
  [types.NEW_COMPARISON](state, { title, industry }) {
    state.activeComparison = {
      title,
      basket: [],
      industry,
      products: []
    };
  },
  [types.ADD_TO_BASKET](state, product) {
    state.activeComparison.basket.push(product);
  },
  [types.SET_INDUSTRY](state, industry) {
    state.activeComparison.industry = industry;
  },
  [types.SET_PRODUCTS](state, products) {
    // state.activeComparison.products = products;
    Vue.set(state.activeComparison, 'products', products);
  },
  [types.REMOVE_PRODUCT](state, productId) {
    // Remove from basket
    const basketIndex = state.activeComparison.basket.findIndex((p) => parseInt(p.id) === parseInt(productId));
    if (basketIndex > -1) {
      state.activeComparison.basket.splice(basketIndex, 1);
    }

    // Remove product details
    if (state.activeComparison.products && state.activeComparison.products.length > 0) {
      const productsIndex = state.activeComparison.products.findIndex((p) => parseInt(p.id) === parseInt(productId));

      if (productsIndex > -1) {
        state.activeComparison.products.splice(productsIndex, 1);
      }
    }
  },
  [types.SET_BASKET_OPEN_STATE](state, isOpen) {
    state.basketOpen = isOpen;
  },
  [types.SET_BASKET_DISABLED_STATE](state, isDisabled) {
    state.basketDisabled = isDisabled;
  },
  [types.SET_ADD_TO_BASKET_TEMP](state, addToBasketTemp) {
    state.addToBasketTemp = addToBasketTemp;
  },
  [types.SET_ACTIVE_COMPARISON](state, comparison) {
    state.activeComparison = comparison;
  },
  [types.UPDATE_ACTIVE_COMPARISON](state, comparison) {
    const { activeComparison } = state;
    Object.keys(comparison).forEach((key) => {
      if (!activeComparison[key] || activeComparison.key !== comparison[key]) {
        Vue.set(activeComparison, key, comparison[key]);
      }
    });
  }
};
