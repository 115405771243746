<template>
  <transition
    name="fadeRight">
    <div
      v-if="mobileMenuVisible"
      class="ce-mobileMenu">
      <div class="ce-mobileMenu-logoWrapper">
        <img
          src="https://assets-static.colormaterials.sunchemical.com/logo/sun-chemical-logo-white.png"
          alt="Sun Chemical">
      </div>
      <a
        class="ce-mobileMenuCloseCta"
        @click.prevent="close">
        <fa-icon icon="times" />
      </a>
      <nav>
        <ul class="list-reset">
          <menu-item
            v-for="rootPage in menuItems"
            :key="rootPage.id"
            :item="rootPage" />
        </ul>
      </nav>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Menu from 'mixins/Menu';
import MenuItem from './MenuItem.vue';

export default {
  components: { MenuItem },
  mixins: [Menu],
  computed: {
    ...mapState('generic', ['mobileMenuVisible'])
  },
  watch: {
    mobileMenuVisible(visible) {
      if (visible) {
        document.getElementsByTagName('body')[0].classList.add('ce-mobileMenuVisible');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('ce-mobileMenuVisible');
      }
    }
  },
  methods: {
    ...mapActions('generic', ['hideMobileMenu']),
    close() {
      this.hideMobileMenu();
    }
  }
};
</script>

<style lang="less">
  @import (reference) "~styles/includes/base";

  .ce-mobileMenu {
    position: fixed;
    right: 0;
    top: 0;
    padding-bottom: @space-5;
    background: @color-light;
    .ms(3);
    text-shadow: 2px 2px 2px fade(@color-main, 70);
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    .ms(3);

    @media screen and (min-width: @breakpoint-xxs) {
      max-width: 400px;
    }

    .ce-mobileMenu-logoWrapper {
      width: calc(100% ~'-' @space-4);
      max-width: 250px;
      margin: 80px auto @space-3;
      position: relative;

      img {
        max-width: 100%;
        display: block;
      }
    }

    .ce-mobileMenuCloseCta {
      position: absolute;
      top: @space-3;
      right: @space-3;
      .ms(2);
      cursor: pointer;
      color: @color-mono-100;
    }

    a {
      color: @color-mono-100;
    }

    nav {
      ul {
        display: block;
        margin: 0;
      }
    }
  }

  body.ce-mobileMenuVisible {
    overflow: hidden;

    header, main, footer, .ce-pageWrapper:after {
      filter: blur(2px) grayscale(1);
    }
  }
</style>
