<template>
  <li
    class="ce-menuItem ce-menuItem--desktop"
    :class="[item.linkingMode, {'ce-menuItem--root': isRootLevel}, {'ce-menuItem--secondary': !isRootLevel}]"
    @mouseenter="showSubNav"
    @mouseleave="hideSubNav">
    <a
      :target="item.target"
      :href="item.link">
      {{ item.menuTitle }}
    </a>

    <transition
      :css="false"
      @enter="enterAnimation"
      @leave="leaveAnimation">
      <nav
        v-if="hasSubNav && subNavVisible"
        class="ce-secondLevelNav"
        :style="{top: `${topOffset}px`}">
        <ul class="list-reset">
          <menu-item
            v-for="subPage in item.children"
            :key="subPage.id"
            :item="subPage"
            :is-root-level="false" />
        </ul>
      </nav>
    </transition>
  </li>
</template>

<script>
import slideAnimation from 'mixins/slideAnimation';
import MenuItem from 'mixins/MenuItem';

export default {
  mixins: [slideAnimation, MenuItem],
  props: {
    topOffset: {
      type: Number,
      required: false,
      default: 50
    }
  },
  name: 'MenuItem',
  methods: {
    showSubNav() {
      if (!this.hasSubNav) return;

      if (!this.subNavVisible) {
        this.subNavVisible = true;
      }
    },
    hideSubNav() {
      if (!this.hasSubNav) return;
      this.subNavVisible = false;
    }
  }
};
</script>

<style lang="less">
@import (reference) "~styles/includes/base";

.ce-menuItem.ce-menuItem--desktop {
  &.ce-menuItem--root {
    > a {
      position: relative;
      padding: @space-3 0;
      display: block;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 50%;
        height: 5px;
        background: @color-mono-100;
        opacity: 0;
        transition: all 250ms ease-in-out;
      }
    }

    &.current, &:hover {
      > a:after {
        opacity: 1;
        width: 100%;
      }
    }
  }

  .ce-secondLevelNav {
    position: absolute;
    left: 0;
    width: 100%;
    background: @color-sc-light-green;

    ul {
      flex-direction: column;
    }

    .ce-menuItem.ce-menuItem--secondary {
      padding: @space-3;

      &.current, &:hover {
        a {
          color: @color-mono-0;
        }
      }
    }
  }
}
</style>
