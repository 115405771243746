var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fadeRight" } }, [
    _vm.mobileMenuVisible
      ? _c("div", { staticClass: "ce-mobileMenu" }, [
          _c("div", { staticClass: "ce-mobileMenu-logoWrapper" }, [
            _c("img", {
              attrs: {
                src: "https://assets-static.colormaterials.sunchemical.com/logo/sun-chemical-logo-white.png",
                alt: "Sun Chemical",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "ce-mobileMenuCloseCta",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [_c("fa-icon", { attrs: { icon: "times" } })],
            1
          ),
          _vm._v(" "),
          _c("nav", [
            _c(
              "ul",
              { staticClass: "list-reset" },
              _vm._l(_vm.menuItems, function (rootPage) {
                return _c("menu-item", {
                  key: rootPage.id,
                  attrs: { item: rootPage },
                })
              }),
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }