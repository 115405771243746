<template>
  <div
    class="ce-field ce-multiSelectField"
    :class="{'ce-multiSelectField--withOtherOption': otherOptionActive}">
    <ValidationProvider
      :name="validatorName"
      v-slot="{ errors }"
      :rules="rules"
      :mode="mode"
      tag="div">
      <label
        v-if="label"
        :for="name">
        {{ label }} <span v-if="isRequired && showRequiredIndicator">*</span>
        <span
          v-if="errors.length > 0"
          class="ms-2 ml1 regular ce-colorError">
          {{ errors[0] }}
        </span>
      </label>

      <div class="ce-multiSelectField-fieldWrapper">
        <div>
          <multiselect
            :id="name"
            :name="name"
            :value="value"
            :options="preparedOptions"
            label="title"
            track-by="value"
            :multiple="true"
            :preserve-search="false"
            :hide-selected="true"
            placeholder=""
            :disabled="disabled"
            @input="handleInput" />
        </div>
      </div>
    </ValidationProvider>

    <ValidationProvider
      v-if="otherOptionActive"
      :name="validatorName"
      v-slot="{ errors }"
      :rules="otherRules"
      :mode="mode"
      tag="div">
      <label v-if="label">
        {{ $t('generic.otherOption') }} <span v-if="isOtherRequired && showRequiredIndicator">*</span>
        <span
          v-if="errors.length > 0"
          class="ms-2 ml1 regular ce-colorError">
          {{ errors[0] }}
        </span>
      </label>
      <input
        type="text"
        :disabled="disabled"
        :value="other"
        @input="handleCustomInput($event.target.value)"
        autocomplete=""
        autocapitalize="">
    </ValidationProvider>
  </div>
</template>

<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import { sortArray } from 'utils';

export default {
  components: { Multiselect },
  props: {
    value: {
      // eslint-disable-next-line no-bitwise
      type: String | Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      required: true
    },

    validatorName: {
      type: String,
      required: false,
      default() {
        return this.label;
      }
    },

    rules: {
      type: String,
      required: false,
      default: null
    },

    mode: {
      type: String,
      required: false,
      default: 'eager'
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    showRequiredIndicator: {
      type: Boolean,
      required: false,
      default: true
    },

    showOtherOption: {
      type: Boolean,
      required: false,
      default: false
    },

    forceRequiredForOtherOption: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      other: null
    };
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.indexOf('required') !== -1;
    },
    isOtherRequired() {
      return this.otherRules && this.otherRules.indexOf('required') !== -1;
    },
    preparedOptions() {
      const options = Object.keys(this.options).map((value) => ({ title: this.options[value], value }));

      if (this.showOtherOption) {
        options.push({ title: this.$t('generic.otherOption'), value: 'other' });
      }

      return sortArray(options, 'title', 'asc');
    },
    otherOptionActive() {
      return this.showOtherOption
          && this.value
          && Array.isArray(this.value)
          && this.value.find((o) => o.value === 'other');
    },
    otherRules() {
      if (this.forceRequiredForOtherOption && !this.isRequired) {
        return this.rules ? `${this.rules} required` : 'required';
      }

      return this.rules;
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleInput(event) {
      this.$emit('input', event);
    },
    handleCustomInput(value) {
      this.other = value;
      const result = this.value;
      const otherOption = result.find((o) => o.value === 'other');
      Vue.set(otherOption, 'customInput', value);
      this.$emit('input', result);
    }
  }
};
</script>

<style lang="less">
@import (reference) '~styles/includes/base';

.ce-field.ce-multiSelectField {
  &.ce-multiSelectField--withOtherOption {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    > div {
      width: 100%;

      @media screen and (min-width: @breakpoint-xs) {
        &:first-child {
          width: 70%;
          padding-right: @space-2;
        }

        &:last-child {
          width: 30%;
          padding-left: @space-2;
        }
      }

      .ce-field {
        margin-bottom: 0;
      }
    }
  }
}
</style>
