import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from 'src/lang/en.json';

Vue.use(VueI18n);

const currentLocale = () => 'en';

const currentLangFile = () => {
  const langFiles = { en };

  return langFiles[currentLocale()];
};

const translate = (path, params = null) => {
  let result;
  path.split('.').forEach((part) => {
    result = result ? result[part] : currentLangFile()[part];
  });

  if (params && typeof params === 'object' && result && typeof result === 'string') {
    Object.keys(params).forEach((param) => {
      if (result.indexOf(`{${param}}`) > -1) {
        result = result.replace(`{${param}}`, params[param]);
      }
    });
  }

  return result;
};

const i18n = new VueI18n({
  locale: currentLocale(),
  fallbackLocale: 'en',
  messages: { en }
});

export {
  i18n, currentLocale, translate
};
