import axios from 'axios';
import qs from 'qs';
import { updateUrlByParams } from 'utils/url';
import SearchConfig from '@sun-chemical/system-config/search-config';

// Setup api client
const apiClient = axios.create({
  baseURL: payload.services.searchservice.url,
  headers: {
    'request-region': payload.generic.requestRegion
  }
});

export default {
  /**
   * Search pigment assets based on the current location.search string.
   *
   * @param params
   * @param {string|null} token The users JWT for access level detection, only public infos will be visible without token
   * @return array
   */
  async search(params, token = null) {
    const { urlParams } = updateUrlByParams(params);

    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return (await apiClient.post(`/api/v1/document/search${urlParams}`, {}, config)).data;
  },

  async loadMore(from, token = null) {
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return (await apiClient.post(`/api/v1/document/search${location.search}`, { from }, config)).data;
  },

  async loadAll(size, token = null) {
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return (await apiClient.post(
      `/api/v1/document/search${location.search}`,
      {
        size,
      },
      config
    )).data;
  },

  async suggest(term, activeFilters, token = null) {
    const { urlParams } = updateUrlByParams({
      ...activeFilters,
      [SearchConfig.getMap().search_term.param]: term
    });

    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return (await apiClient.get(`api/v1/document/suggest${urlParams}`, config)).data;
  },

  async getAggregations(token = null, params = null) {
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    let url = '/api/v1/document/search';

    if (params) {
      url += `?${qs.stringify(params, { arrayFormat: 'comma', encode: false })}`;
    }

    return (await apiClient.post(
      url,
      {
        size: 0,
      },
      config
    )).data;
  },

  async getProductSuggestions(token = null, params = null) {
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    let url = '/api/v1/document/search';

    if (params) {
      url += `?${qs.stringify(params, { arrayFormat: 'comma', encode: false })}`;
    }

    return (await apiClient.post(
      url,
      {
        size: 10,
        isProductSuggestRequest: true,
      },
      config
    )).data?.hits;
  },

  async searchMySheets(params, token) {
    const { urlParams } = updateUrlByParams(params);

    return (await apiClient.post(`/api/v1/document/mysheets${urlParams}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })).data;
  },

  async loadMoreMySheets(from, token) {
    return (await apiClient.post(`/api/v1/document/mysheets${location.search}`, { from }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })).data;
  },

  async loadAllMySheets(size, token) {
    return (await apiClient.post(`/api/v1/document/mysheets${location.search}`, { size }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })).data;
  },

  async getChangedMySheets(user) {
    const params = {
      tab: 'mysheets',
      min_doc_date: user.previousLogin
    };

    const urlParams = qs.stringify(params, { arrayFormat: 'comma', encode: false });
    return (await apiClient.post(`/api/v1/document/mysheets?${urlParams}`, {}, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    })).data;
  },
};
