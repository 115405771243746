var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        name: "fade",
        "enter-active-class": "fadeInUp",
        "leave-active-class": "fadeOutDown",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "ce-backToTopButton",
          class: {
            "ce-backToTopButton--secondary": _vm.isFloatingActionVisible,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.scrollTop.apply(null, arguments)
            },
          },
        },
        [
          _c("fa-icon", { attrs: { icon: "arrow-up" } }),
          _vm._v("\n    " + _vm._s(_vm.$t("generic.backToTopCta")) + "\n  "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }