import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import {
  faUser, faCog, faSignOutAlt, faSearch, faAngleDown, faCheck, faIndustry,
  faSpinner, faEye, faHeadset, faPrint, faLongArrowAltLeft, faTimes, faFilter, faSearchPlus,
  faEyeSlash, faExclamationTriangle, faArrowRight, faHourglassHalf, faTrashAlt,
  faUsersCog, faExternalLinkAlt, faBars, faExclamation, faInfoCircle, faMinusCircle,
  faVial, faAngleLeft, faAngleRight, faMapMarkerAlt, faTruck, faQuestionCircle,
  faExclamationCircle, faList, faPlusCircle, faUserSecret, faSignature, faFolderOpen,
  faSave, faServer, faDownload, faStar as SolidStar, faStarHalfAlt, faHistory,
  faExchangeAlt, faGlobe, faBoxOpen, faPencilAlt, faCaretDown, faTachometerAlt,
  faEnvelope as faEnvelopeSolid, faNotEqual, faChevronUp, faArrowUp, faHdd, faCloudDownloadAlt,
  faLanguage, faLink, faLock, faPowerOff, faLongArrowAltRight, faMobileAlt,
  faQrcode, faBook, faSort, faSortUp, faSortDown, faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
  faEnvelope, faFilePdf, faCheckCircle, faClock, faStar as RegularStar, faFile, faLightbulb,
  faFileImage, faFileExcel, faFileWord, faFileAlt, faFilePowerpoint, faFileAudio, faFileVideo
} from '@fortawesome/free-regular-svg-icons';

import {
  faLinkedinIn, faFacebook, faTwitter, faYoutube, faInstagram, faFlickr, faSlideshare
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faUser,
  faCog,
  faSignOutAlt,
  faSearch,
  faAngleDown,
  faCheck,
  faIndustry,
  faSpinner,
  faEye,
  faEyeSlash,
  faLongArrowAltLeft,
  faTimes,
  faFilter,
  faExclamationTriangle,
  faLinkedinIn,
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faFlickr,
  faSlideshare,
  faEnvelope,
  faHeadset,
  faPrint,
  faFilePdf,
  faSearchPlus,
  faArrowRight,
  faCheckCircle,
  faHourglassHalf,
  faTrashAlt,
  faUsersCog,
  faExternalLinkAlt,
  faBars,
  faExclamation,
  faInfoCircle,
  faMinusCircle,
  faClock,
  faVial,
  faAngleLeft,
  faAngleRight,
  faMapMarkerAlt,
  faTruck,
  faQuestionCircle,
  faExclamationCircle,
  faList,
  faPlusCircle,
  faUserSecret,
  faSignature,
  faFolderOpen,
  faSave,
  faServer,
  faDownload,
  SolidStar,
  RegularStar,
  faStarHalfAlt,
  faHistory,
  faExchangeAlt,
  faGlobe,
  faBoxOpen,
  faFile,
  faPencilAlt,
  faCaretDown,
  faTachometerAlt,
  faEnvelopeSolid,
  faNotEqual,
  faChevronUp,
  faArrowUp,
  faHdd,
  faCloudDownloadAlt,
  faLanguage,
  faLink,
  faLock,
  faPowerOff,
  faLongArrowAltRight,
  faMobileAlt,
  faQrcode,
  faLightbulb,
  faBook,
  faSort,
  faSortUp,
  faSortDown,
  faFileImage,
  faFileExcel,
  faFileWord,
  faFileAlt,
  faFilePowerpoint,
  faFileAudio,
  faFileVideo,
  faCalendarAlt,
);

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers);

const getIconForMimeType = (mimeType) => {
  if (!mimeType || typeof mimeType !== 'string') {
    return ['far', 'file'];
  }

  if (mimeType.indexOf('image/') === 0) {
    return ['far', 'file-image'];
  }

  if (mimeType === 'application/pdf') {
    return ['far', 'file-pdf'];
  }

  if (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return ['far', 'file-excel'];
  }

  if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return ['far', 'file-word'];
  }

  if (mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
    return ['far', 'file-powerpoint'];
  }

  if (mimeType === 'text/plain') {
    return ['far', 'file-alt'];
  }

  if (mimeType.indexOf('video/') === 0) {
    return ['far', 'file-video'];
  }

  if (mimeType.indexOf('audio/') === 0) {
    return ['far', 'file-audio'];
  }

  return ['far', 'file'];
};

export { getIconForMimeType };
