var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "modal",
        {
          staticClass: "ce-changedMySheetsHint",
          attrs: {
            headline: _vm.$t("changedMySheetsHint.headline"),
            closable: true,
          },
          on: {
            close: function ($event) {
              _vm.isOpen = false
            },
          },
        },
        [
          _c("div", { staticClass: "ce-modalContent" }, [
            _c("div", { staticClass: "mb4" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("changedMySheetsHint.message")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _vm.assetsManifest
              ? _c(
                  "div",
                  _vm._l(_vm.changedMySheets.hits, function (hit) {
                    return _c("changed-product-row", {
                      key: hit.id,
                      attrs: { product: hit, manifest: _vm.assetsManifest },
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt4 flex flex-wrap justify-center mxn1" },
              [
                _c(
                  "a",
                  {
                    staticClass: "ce-btn ce-btn-light",
                    attrs: { href: _vm.mySheetsLink },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("changedMySheetsHint.openCta")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }