import { mapState } from 'vuex';
import { getEventKeyCode } from 'utils';

export default {
  props: {
    closable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyup, false);

    this.storeOffset();
    document.getElementsByTagName('body')[0].classList.add('overflow-hidden');

    // eslint-disable-next-line no-underscore-dangle
    this.$store.dispatch('generic/addToOverlayStack', this._uid, { root: true });
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyup, false);

    document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');

    this.restoreOffset();

    // eslint-disable-next-line no-underscore-dangle
    this.$store.dispatch('generic/removeFromOverlayStack', this._uid, { root: true });
  },
  computed: {
    ...mapState('generic', ['overlayStack']),
    isLatestOverlay() {
      const latestOverlay = this.overlayStack[this.overlayStack.length - 1];

      // eslint-disable-next-line no-underscore-dangle
      return latestOverlay === this._uid;
    }
  },
  methods: {
    close(force = false) {
      if (this.closable || force) {
        this.$emit('close');
      }
    },
    handleKeyup(e) {
      // Ignore events if this overlay is not the latest open one
      if (!this.isLatestOverlay) return;

      if (e.target.nodeName === 'INPUT') return;
      if (getEventKeyCode(e) === 27) { this.close(); }
    },
    storeOffset() {
      document.body.style.top = `-${window.pageYOffset}px`;
    },
    restoreOffset() {
      const offset = document.body.style.top;
      document.body.style.top = '';
      window.scrollTo(0, parseInt(offset || '0') * -1);
    }
  }
};
