var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ce-modal" }, [
    _c(
      "div",
      { staticClass: "ce-modalInner" },
      [
        _vm.closable
          ? _c(
              "a",
              {
                staticClass: "ce-modalCloseCta",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.close.apply(null, arguments)
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "times" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showHeader
          ? _c("div", { staticClass: "ce-modalHeader" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm.hasHeaderContent
                ? _c("div", { staticClass: "ce-modalHeader-content" }, [
                    _vm.headline
                      ? _c("h2", [
                          _vm._v(
                            "\n          " + _vm._s(_vm.headline) + "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _vm._t("description", function () {
                          return [
                            _vm.description
                              ? _c("p", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.description) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ce-modalHeader-logoWrapper" }, [
      _c("img", {
        attrs: {
          src: "https://assets-static.colormaterials.sunchemical.com/logo/sun-chemical-logo.png",
          alt: "Sun Chemical",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }