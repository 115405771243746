import axios from 'axios';

export default {
  apiClient(state, getters, rootState) {
    if (!state.apiClient) {
      state.apiClient = axios.create({
        baseURL: `${payload.services.userservice.url}/api/v1/product-comparison`
      });

      if (rootState.user.activeUser) {
        state.apiClient.defaults.headers.common.Authorization = `Bearer ${rootState.user.activeUser.token}`;
      }
    }

    return state.apiClient;
  },
  products(state) {
    return state.activeComparison && state.activeComparison.products
      ? state.activeComparison.products
      : [];
  },
  basket(state) {
    return state.activeComparison && state.activeComparison.basket
      ? state.activeComparison.basket
      : [];
  },
  productIds(state, getters) {
    return getters.basket.map((p) => parseInt(p.id));
  },
  infoFetched(state, getters) {
    return getters.products.length > 0;
  },
  isComparisonActive(state) {
    return state.activeComparison && state.activeComparison.basket && state.activeComparison.basket.length > 0;
  },
  basketLimitReached(state, getters) {
    return getters.basket.length > 3;
  },
  activeComparisonIndustry(state, getters, rootState) {
    if (!state.activeComparison || !state.activeComparison.industry) return null;

    return rootState.generic.industries.find((i) => i.code === state.activeComparison.industry);
  },
  canExportComparison(state, getters) {
    return getters.isComparisonActive && getters.basket.length >= 2;
  },
  canSaveComparison(state, getters, rootState) {
    return getters.isComparisonActive && !state.activeComparison.id && getters.basket.length >= 2 && !!rootState.user.activeUser;
  },
  isUnsavedComparisonActive(state, getters) {
    return getters.isComparisonActive && !state.activeComparison.id;
  },
  isSavedComparisonActive(state, getters) {
    return getters.isComparisonActive && state.activeComparison.id;
  },
  isBasketVisible(state, getters) {
    return !state.basketDisabled && getters.isComparisonActive;
  },
  productsWithImageStacks(state, getters) {
    return getters.products.filter((p) => p.imageStacks && Array.isArray(p.imageStacks) && p.imageStacks.length > 0);
  },
  showQrCode(state, getters) {
    return getters.productsWithImageStacks.length > 1;
  }
};
