import acl from '@sun-chemical/system-config/access-level';
import { checkPermission } from 'utils';

export default {
  userRoles(state) {
    return state.activeUser ? state.activeUser.tokenPayload.roles : null;
  },
  permissions(state) {
    const permissions = {};

    Object.values(acl).forEach((accessLevel) => {
      permissions[accessLevel] = checkPermission(accessLevel, state.activeUser);
    });

    return permissions;
  },
  token(state) {
    return state.activeUser ? state.activeUser.token : null;
  },
  tokenPayload(state) {
    return state.activeUser ? state.activeUser.tokenPayload : null;
  },
  groups(state, getters) {
    return getters.tokenPayload ? getters.tokenPayload.groups : null;
  },
  industryGroup(state, getters, rootState) {
    const { groups } = getters;
    const { industries } = rootState.generic;

    if (groups && Array.isArray(groups) && groups.length > 0) {
      const industryGroup = groups.find((group) => industries.map((i) => i.code).indexOf(group) > -1);

      return industryGroup ? industries.find((i) => i.code === industryGroup) : null;
    }

    return null;
  },
  userStatus(state, getters) {
    if (!state.activeUser) return 'public';

    if (getters.isInternal) return 'internal';

    if (getters.isValidated) return 'validated';

    return 'public-registered';
  },
  userTrackingId(state, getters) {
    if (state.activeUser && getters.isPersonalizedTrackingEnabled) {
      return state.activeUser.email;
    }

    return 'anonymous';
  },
  userTrackingCompany(state) {
    if (state.activeUser && state.activeUser.company) {
      return state.activeUser.company;
    }

    return 'unknown';
  },
  userTrackingAccountManager(state) {
    if (state.activeUser
        && state.activeUser.accountManager
        && state.activeUser.accountManager.email) {
      return state.activeUser.accountManager.email;
    }

    return 'undefined';
  },
  previousLogin(state) {
    return state.activeUser && state.activeUser.previousLogin ? state.activeUser.previousLogin : undefined;
  },
  isInternal(state, getters) {
    return getters.userRoles ? getters.userRoles.indexOf('internal') > -1 : false;
  },
  isValidated(state, getters) {
    return getters.userRoles ? getters.userRoles.indexOf('validated') > -1 : false;
  },
  isAdmin(state, getters) {
    return getters.userRoles ? getters.userRoles.indexOf('admin') > -1 : false;
  },
  requiredActions(state) {
    return state.activeUser && state.activeUser.requiredActions && Array.isArray(state.activeUser.requiredActions)
      ? state.activeUser.requiredActions
      : [];
  },
  showActionRequiredHint(state, getters) {
    return getters.requiredActions && getters.requiredActions.length > 0;
  },
  initialPasswordChangeRequired(state, getters) {
    return getters.requiredActions.indexOf('changeInitialPassword') > -1;
  },
  canRequestSample(state, getters, rootState) {
    return state.activeUser && getters.isValidated;
  },
  userConsent(state) {
    return state.activeUser && state.activeUser.consent ? state.activeUser.consent : [];
  },
  isPersonalizedTrackingEnabled(state, getters) {
    return getters.userConsent.indexOf('personalizedTracking') > -1;
  },
  isPigmentEditor(state, getters) {
    if (getters.userRoles) {
      return getters.userRoles.indexOf('pigment-editor') > -1
        || getters.userRoles.indexOf('admin') > -1;
    }

    return false;
  },
  canViewCompetitorApp(state, getters) {
    if (getters.userRoles) {
      return getters.userRoles.indexOf('competitor-viewer') > -1
        || getters.userRoles.indexOf('admin') > -1;
    }

    return false;
  },
  isCatalogManager(state, getters) {
    if (getters.userRoles) {
      return getters.userRoles.indexOf('catalog-manager') > -1
          || getters.userRoles.indexOf('admin') > -1;
    }

    return false;
  },
  canViewFormulations(state, getters, rootState) {
    if (typeof rootState.generic.pagePermissions?.formulationRepositoryPage?.accessDeniedThroughRestriction !== 'undefined') {
      return !rootState.generic.pagePermissions.formulationRepositoryPage.accessDeniedThroughRestriction;
    }

    return true;
  },
  isDocumentManager(state, getters) {
    if (getters.userRoles) {
      return getters.userRoles.indexOf('document-manager') > -1
      || getters.userRoles.indexOf('admin') > -1;
    }

    return false;
  }
};
