var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "ce-menuItem ce-menuItem--desktop",
      class: [
        _vm.item.linkingMode,
        { "ce-menuItem--root": _vm.isRootLevel },
        { "ce-menuItem--secondary": !_vm.isRootLevel },
      ],
      on: { mouseenter: _vm.showSubNav, mouseleave: _vm.hideSubNav },
    },
    [
      _c("a", { attrs: { target: _vm.item.target, href: _vm.item.link } }, [
        _vm._v("\n    " + _vm._s(_vm.item.menuTitle) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { css: false },
          on: { enter: _vm.enterAnimation, leave: _vm.leaveAnimation },
        },
        [
          _vm.hasSubNav && _vm.subNavVisible
            ? _c(
                "nav",
                {
                  staticClass: "ce-secondLevelNav",
                  style: { top: `${_vm.topOffset}px` },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-reset" },
                    _vm._l(_vm.item.children, function (subPage) {
                      return _c("menu-item", {
                        key: subPage.id,
                        attrs: { item: subPage, "is-root-level": false },
                      })
                    }),
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }