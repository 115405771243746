var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "ce-changeInitialPassword",
      attrs: {
        headline: _vm.$t("changeInitialPasswordModal.headline"),
        description: _vm.$t("changeInitialPasswordModal.description"),
        closable: false,
      },
    },
    [
      _c(
        "div",
        { staticClass: "ce-modalContent" },
        [
          _c(
            "ValidationObserver",
            { ref: "validationObserver", attrs: { tag: "div" } },
            [
              _c("password-field", {
                attrs: {
                  name: "password",
                  value: _vm.form.password,
                  label: _vm.$t("changeInitialPasswordModal.password"),
                },
                on: { input: _vm.onInput },
              }),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "ce-btn ce-btn-light ce-btn--fullWidth",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.loading
                      ? [
                          _c("fa-icon", {
                            attrs: { icon: "spinner", spin: "" },
                          }),
                        ]
                      : [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("changeInitialPasswordModal.submit")
                              ) +
                              "\n          "
                          ),
                        ],
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }