import Cookies from 'js-cookie';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const namespaced = true;

const state = {
  activeComparisonSessionKey: 'ce-activeComparison',
  activeComparison: null,
  basketOpen: false,
  basketDisabled: false,
  addToBasketTemp: null,
  apiClient: null,
  isSharedComparison: false
};

if (typeof payload !== 'undefined' && payload && payload.sharedComparison) {
  state.activeComparison = payload.sharedComparison;
  state.isSharedComparison = true;
} else if (Cookies.get(state.activeComparisonSessionKey)) {
  state.activeComparison = JSON.parse(Cookies.get(state.activeComparisonSessionKey));
}

export {
  state,
  getters,
  actions,
  mutations
};
