import { debugMsg } from 'utils/index';

export default {
  initiated: false,

  pageViewTracked: false,

  /**
   * Set the matomo user id.
   *
   * This should only happen if the user accepted the "marketing" cookies.
   * See the generic vuex module init method for details.
   *
   * @param userId
   */
  setUser(userId) {
    debugMsg(`set analytics user id to ${userId}`);
    _paq.push(['setUserId', userId]);
  },

  init() {
    if (!this.initiated) {
      this.initiated = true;
      debugMsg('init analytics');
      this.giveConsent();
      this.enableExternalLinkTracking();
    }
  },

  giveConsent() {
    _paq.push(['setConsentGiven']);
  },

  trackPageView() {
    if (!this.pageViewTracked) {
      this.pageViewTracked = true;
      debugMsg('track page view');
      _paq.push(['trackPageView']);
    }
  },

  enableExternalLinkTracking() {
    _paq.push(['enableLinkTracking']);
  },

  disable() {
    _paq.push(['disableCookies']);
    this.initiated = false;
  },

  /**
   * Track a event.
   *
   * @param {string} category e.g. "movie"
   * @param {string} action e.g. "rate"
   * @param {string|null} name e.g. "Iron Man"
   * @param {string|number|null} value 10
   */
  trackEvent(category, action, name = null, value = null) {
    const params = [
      category,
      action
    ];

    if (name) {
      params.push(name);

      if (value) {
        params.push(value);
      }
    }

    debugMsg('track event', params.join(' :: '));

    _paq.push(['trackEvent', ...params]);
  },

  /**
   * Track a site search.
   *
   * @param {string} term The search term itself
   * @param {string} category All active filters in url representation
   * @param {number} results The amount of total results
   */
  trackSearch(term, category, results) {
    _paq.push(['trackSiteSearch',
      term,
      category,
      results
    ]);
  }
};
