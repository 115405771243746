var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ce-blockContent" },
    [
      _c(
        "transition",
        {
          attrs: { css: false },
          on: { enter: _vm.enterAnimation, leave: _vm.leaveAnimation },
        },
        [
          !_vm.isOpen
            ? _c("div", [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggle.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.ctaLabel) + "\n      ")]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { css: false },
          on: { enter: _vm.enterAnimation, leave: _vm.leaveAnimation },
        },
        [
          _vm.isOpen
            ? _c("div", [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
                _vm._v(" "),
                _vm.showCloseCta
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.toggle.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.payload.closeCta) +
                              "\n        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }