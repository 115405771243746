var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ValidationObserver",
    { ref: "validationObserver", attrs: { tag: "div" } },
    [
      _vm.hasMessage
        ? _c("alert", {
            staticClass: "mb3",
            attrs: { message: _vm.message.content, type: _vm.message.type },
            on: {
              close: function ($event) {
                _vm.message = {}
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.finished
        ? [
            _vm._l(_vm.fields, function (field) {
              return _c(
                "div",
                {
                  key: field.key,
                  staticClass: "ce-fieldWrapper",
                  class: [`ce-fieldWrapper--${field.type}`],
                },
                [
                  field.type === "input"
                    ? [
                        _c("validated-input", {
                          attrs: {
                            name: field.key,
                            value: _vm.form[field.key],
                            label: field.label,
                            disabled:
                              typeof field.disabled === "boolean"
                                ? field.disabled
                                : false,
                            rules: field.rules ? field.rules : "",
                          },
                          on: { input: _vm.onInput },
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  field.type === "select" &&
                  (!field.multi || field.multi !== true)
                    ? [
                        _c("validated-select", {
                          attrs: {
                            name: field.key,
                            label: field.label,
                            disabled:
                              typeof field.disabled === "boolean"
                                ? field.disabled
                                : false,
                            rules: field.rules ? field.rules : "",
                            options: field.options,
                            "show-empty-default": field.emptyDefault,
                          },
                          model: {
                            value: _vm.form[field.key],
                            callback: function ($$v) {
                              _vm.$set(_vm.form, field.key, $$v)
                            },
                            expression: "form[field.key]",
                          },
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  field.type === "select" && field.multi === true
                    ? [
                        _c("validated-multiselect", {
                          attrs: {
                            name: field.key,
                            label: field.label,
                            disabled:
                              typeof field.disabled === "boolean"
                                ? field.disabled
                                : false,
                            rules: field.rules ? field.rules : "",
                            options: field.options,
                            "show-empty-default": field.emptyDefault,
                            "show-other-option":
                              typeof field.showOtherOption === "boolean"
                                ? field.showOtherOption
                                : false,
                          },
                          model: {
                            value: _vm.form[field.key],
                            callback: function ($$v) {
                              _vm.$set(_vm.form, field.key, $$v)
                            },
                            expression: "form[field.key]",
                          },
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  field.type === "label"
                    ? _c("div", { staticClass: "ce-field ce-labelField" }, [
                        _vm._v(
                          "\n        " + _vm._s(field.content) + "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  field.type === "section_header"
                    ? _c("div", { staticClass: "ce-field ce-sectionHeader" }, [
                        _vm._v(
                          "\n        " + _vm._s(field.content) + "\n      "
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              )
            }),
            _vm._v(" "),
            _vm.consentHintNecessary
              ? _c(
                  "div",
                  { staticClass: "ms-1 line-height-4" },
                  [
                    _c(
                      "i18n",
                      { attrs: { path: "form.consentHint", tag: "p" } },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.links.dataProtectionPage,
                              rel: "noopener noreferrer",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("form.dataProtectionPage"))
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mt4" }, [
              _c(
                "button",
                {
                  staticClass: "ce-btn ce-btn-light ce-btn--fullWidth",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.isSending
                    ? [_c("fa-icon", { attrs: { icon: "spinner", spin: "" } })]
                    : [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("form.submitCta")) +
                            "\n        "
                        ),
                      ],
                ],
                2
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }