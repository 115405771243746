import * as types from './mutation-types';

export default {
  [types.SET_USER](state, user) {
    state.activeUser = user;
  },
  [types.UPDATE_USER_TOKEN](state, token) {
    if (state.activeUser) {
      state.activeUser.token = token;
    }
  },
  [types.SHOW_LOGIN](state) {
    state.loginVisible = true;
  },
  [types.HIDE_LOGIN](state) {
    state.loginVisible = false;
  },
  [types.SET_REGISTER_VISIBILITY](state, visibility) {
    state.registrationVisible = visibility;
  },
  [types.SET_FORGOT_PASSWORD_VISIBILITY](state, visibility) {
    state.forgotPasswordVisible = visibility;
  },
  [types.UPDATE_USER_INFO](state, data) {
    Object.keys(data).forEach((key) => {
      state.activeUser[key] = data[key];
    });
  },
  [types.SET_USER_MENU_VISIBILITY](state, visibility) {
    state.userMenuVisible = visibility;
  },
};
