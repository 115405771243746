<template>
  <div
    class="ce-comparisonBasket"
    v-if="!basketDisabled && isComparisonActive"
    :class="{'ce-comparisonBasket--open': basketOpen }">
    <transition
      name="slide"
      enter-active-class="slideInRight"
      leave-active-class="slideOutRight">
      <div
        v-if="basketOpen"
        class="ce-comparisonBasket-content">
        <div class="ce-comparisonBasket-contentInner ce-container">
          <div
            v-if="activeComparisonIndustry"
            class="my2">
            <i18n
              path="productComparison.basket.headline"
              tag="span">
              <strong>{{ activeComparisonIndustry.title }}</strong>
            </i18n>
            <span class="ms-2">{{ $t('productComparison.basket.minMaxHint') }}</span>
          </div>

          <div class="flex items-end ce-comparisonBasket-productsAndActionContainer">
            <div class="ce-comparisonBasket-productsWrapper">
              <div
                class="center ce-comparisonBasket-product"
                v-for="(product, index) in basket"
                :key="product.id">
                <div>
                  <strong>{{ index + 1 }}.</strong>

                  <span class="ce-comparisonBasketProduct-title">
                    {{ product.product_name }}
                  </span>

                  <span
                    class="ce-comparisonBasketProduct-deleteCta"
                    @click.prevent="removeProduct(product.id)">
                    <fa-icon
                      class="ms-3"
                      icon="times" />
                  </span>
                </div>
              </div>

              <div
                class="ce-comparisonBasket-addMoreHint"
                v-if="basket.length === 1">
                <fa-icon
                  icon="info-circle"
                  class="mr2" /> {{ $t('productComparison.basket.addMoreHint') }}
              </div>
            </div>
            <div class="ce-comparisonBasket-actionsWrapper pl4">
              <a
                :disabled="basket.length < 2"
                :href="links.comparePage"
                class="ce-btn ce-btn-light">
                {{ $t('productComparison.basket.compareCta') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="ce-comparisonBasket-toggle">
      <button
        class="ce-btn ce-btn-light"
        @click.prevent="toggleBasket">
        <fa-icon
          v-if="basketOpen"
          icon="times" />

        <fa-layers
          v-else>
          <fa-icon
            icon="list" />
          <span class="fa-layers-counter">
            {{ basket.length }}
          </span>
        </fa-layers>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('productComparison', ['isComparisonActive', 'basket', 'activeComparisonIndustry']),
    ...mapState('productComparison', ['basketOpen', 'basketDisabled', 'activeComparison']),
    ...mapState('generic', ['links'])
  },
  methods: {
    ...mapActions('productComparison', ['toggleBasket', 'removeProduct'])
  }
};
</script>

<style lang="less">
  @import (reference) "~styles/includes/base";

  .ce-comparisonBasket {
    position: fixed;
    z-index: 7000;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .ce-comparisonBasket-contentInner {
      padding: @space-2 0 @space-3;

      @media screen and (max-width: (@breakpoint-xxl - 1)) {
        padding-right: @space-4;
      }

      @media screen and (max-width: (@breakpoint-xl - 1)) {
        padding: @space-2 (50px + (2 * @space-3)) @space-3 2.5%;
        max-width: 100%;
      }
    }

    @media screen and (max-width: (@breakpoint-sm - 1)) {
     .ce-comparisonBasket-contentInner {
       padding: @space-3 0;
       max-width: 90%;
       text-align: center;
     }

      .ce-comparisonBasket-productsAndActionContainer {
        flex-direction: column;
        align-items: center;

        .ce-comparisonBasket-productsWrapper {
          padding-bottom: @space-3;
          justify-content: center;
        }

        .ce-comparisonBasket-actionsWrapper {
          padding: 0 @space-6;
        }
      }
    }

    .ce-comparisonBasket-content {
      .flex-auto;
      min-height: 50px + (2 * @space-3);
      background: @color-mono-92;
      border-top: 2px solid @color-mono-80;

      .ce-comparisonBasket-productsWrapper {
        .flex-auto;
        display: flex;
        flex-wrap: wrap;
        margin: -@space-1;

        > div {
          padding: @space-2 @space-4 + @space-2 @space-2 @space-2;
          border-radius: @border-radius;
          background: @color-mono-50;
          color: @color-mono-100;
          margin: @space-1;
          .ms(-2);
          position: relative;
          overflow: hidden;
          font-weight: 600;

          strong {
            font-weight: 400;
          }

          .ce-comparisonBasketProduct-title {
            padding-right: @space-2;
          }

          .ce-comparisonBasketProduct-deleteCta {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 30px;
            padding: @space-2;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background 250ms ease-in-out;
            cursor: pointer;

            &:hover {
              background: @color-error;
            }
          }

          &.ce-comparisonBasket-addMoreHint {
            padding: @space-2;
            color: @color-warn;
            background: none;
          }
        }
      }
    }

    .ce-comparisonBasket-toggle {
      position: fixed;
      bottom: @space-3;
      right: @space-3;

      button {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        padding: 0;

        .fa-layers-counter {
          color: @color-light;
          background: @color-mono-100;
          transform: scale(1);
          .ms(-3);
          top: -20px;
          right: -15px;
          font-weight: 700;
          border: 2px solid @color-light;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          line-height: 20px;
        }
      }
    }

    &.ce-comparisonBasket--open {
      .ce-comparisonBasket-toggle {
        button {
          background: @color-mono-50;

          &:hover {
            background: @color-light;
          }
        }
      }
    }
  }
</style>
