var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "ce-cookieSettings",
      attrs: {
        headline: _vm.$t("cookieModal.headline"),
        closable: _vm.closable,
      },
      on: { close: _vm.hideCookieModal },
    },
    [
      _c("div", { staticClass: "ce-modalContent" }, [
        !_vm.detailsVisible
          ? _c(
              "div",
              { staticClass: "ce-cookieDescription" },
              [
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("cookieModal.description")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "i18n",
                  {
                    attrs: { path: "cookieModal.furtherInformation", tag: "p" },
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.links.dataProtectionPage,
                          rel: "noopener noreferrer",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cookieModal.dataProtectionPage")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "mt4 flex flex-wrap mxn1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "ce-btn ce-btn-grey m1",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.showDetails.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cookieModal.changeCta")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ce-btn ce-btn-light m1",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.acceptAll.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cookieModal.acceptCta")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.detailsVisible
          ? _c("div", { staticClass: "ce-cookieDetails" }, [
              _c("div", { staticClass: "mb4 ms-1" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.hideDetails.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("fa-icon", { attrs: { icon: "long-arrow-alt-left" } }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cookieModal.backCta")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb3" },
                [
                  _c(
                    "checkbox",
                    {
                      attrs: { id: "cookies_mandatory", disabled: true },
                      model: {
                        value: _vm.customSettings.mandatory,
                        callback: function ($$v) {
                          _vm.$set(_vm.customSettings, "mandatory", $$v)
                        },
                        expression: "customSettings.mandatory",
                      },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("cookieModal.mandatoryCookiesDescription")
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ce-cookieSettings-furtherInfo" }, [
                    _c("div", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.cookieDetails.mandatory =
                                !_vm.cookieDetails.mandatory
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cookieModal.showMandatoryCookiesCta")
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.cookieDetails.mandatory
                      ? _c("div", { staticClass: "mt2" }, [
                          _c("table", [
                            _c("tr", [
                              _c("th", [_vm._v("Name")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Lifetime")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("SECSESSID")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("session")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("ce-cookieSettings")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("30 days")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("ce-activeComparison")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("session")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v("ce-comparisonIndustryHintSeen"),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v("18 months")]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb3" },
                [
                  _c(
                    "checkbox",
                    {
                      attrs: { id: "cookies_functional" },
                      model: {
                        value: _vm.customSettings.functional,
                        callback: function ($$v) {
                          _vm.$set(_vm.customSettings, "functional", $$v)
                        },
                        expression: "customSettings.functional",
                      },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("cookieModal.functionalCookiesDescription")
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ce-cookieSettings-furtherInfo" }, [
                    _c("div", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.cookieDetails.functional =
                                !_vm.cookieDetails.functional
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cookieModal.showFunctionalCookiesCta")
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.cookieDetails.functional
                      ? _c("div", { staticClass: "mt2" }, [
                          _c("table", [
                            _c("tr", [
                              _c("th", [_vm._v("Name")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Lifetime")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("_pk_id")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("13 months")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("_pk_ref")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("6 months")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("_pk_ses")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("30 minutes")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("_pk_cvar")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("30 minutes")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("_pk_testcookie")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("temporary")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("MATOMO_SESSID")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("temporary")]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb3" },
                [
                  _c(
                    "checkbox",
                    {
                      attrs: { id: "cookies_marketing" },
                      model: {
                        value: _vm.customSettings.marketing,
                        callback: function ($$v) {
                          _vm.$set(_vm.customSettings, "marketing", $$v)
                        },
                        expression: "customSettings.marketing",
                      },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("cookieModal.marketingCookiesDescription")
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ce-cookieSettings-furtherInfo" }, [
                    _c("div", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.cookieDetails.marketing =
                                !_vm.cookieDetails.marketing
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cookieModal.showMarketingCookiesCta")
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.cookieDetails.marketing
                      ? _c("div", { staticClass: "mt2" }, [
                          _c("table", [
                            _c("tr", [
                              _c("th", [_vm._v("Name")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Lifetime")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("uslk_e")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("1 day")]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("uslk_s")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("session")]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt4 flex flex-wrap mxn1" }, [
                _c(
                  "button",
                  {
                    staticClass: "ce-btn ce-btn-grey m1",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.saveCustomSettings.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cookieModal.saveSettingsCta")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ce-btn ce-btn-light m1",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.acceptAll.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("cookieModal.acceptAllCta")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }