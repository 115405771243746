<template>
  <div class="ce-userMenu">
    <nav>
      <ul class="ce-userMenu-navSection">
        <li
          v-for="route in settingsRoutes"
          :key="route.path">
          <router-link
            v-if="routerAvailable"
            :to="{name: route.name}">
            <span class="icon">
              <fa-icon
                v-if="route.meta && route.meta.icon"
                :icon="route.meta.icon" />
            </span>

            {{ route.meta.title }}
          </router-link>

          <a
            v-else
            :href="`/profile${route.path}`"
            :key="route.path">
            <span class="icon">
              <fa-icon
                v-if="route.meta && route.meta.icon"
                :icon="route.meta.icon" />
            </span>

            {{ route.meta.title }}
          </a>
        </li>
      </ul>

      <ul
        class="ce-userMenu-navSection"
        v-if="dataRoutes.length > 0">
        <li
          v-for="route in dataRoutes"
          :key="route.path">
          <router-link
            v-if="routerAvailable"
            :to="{name: route.name}">
            <span class="icon">
              <fa-icon
                v-if="route.meta && route.meta.icon"
                :icon="route.meta.icon" />
            </span>

            {{ route.meta.title }}
          </router-link>

          <a
            v-else
            :href="`/profile${route.path}`"
            :key="route.path">
            <span class="icon">
              <fa-icon
                v-if="route.meta && route.meta.icon"
                :icon="route.meta.icon" />
            </span>

            {{ route.meta.title }}
          </a>
        </li>
      </ul>

      <ul
        v-if="staticRoutes.length > 0"
        class="ce-userMenu-navSection">
        <li
          v-for="route in staticRoutes"
          :key="route.path">
          <a
            :href="route.path"
            :key="route.path">
            <span class="icon">
              <fa-icon
                v-if="route.icon"
                :icon="route.icon" />
            </span>

            {{ route.title }}
          </a>
        </li>
      </ul>

      <ul class="ce-userProfileNavigation-actions">
        <li
          v-if="user.hasBackendAccess">
          <a
            href="/admin"
            target="_blank">
            <span class="icon"><fa-icon :icon="['fas', 'server']" /></span>

            {{ $t('userProfile.actions.backendCta') }}
          </a>
        </li>

        <li
          v-if="user.canManageUsers">
          <a
            :href="userManagementLink"
            target="_blank">
            <span class="icon"><fa-icon :icon="['fas', 'users-cog']" /></span>

            {{ $t('userProfile.actions.manageUsersCta') }}
          </a>
        </li>

        <li>
          <a
            :href="logoutUrl">
            <span class="icon"><fa-icon :icon="['fas', 'sign-out-alt']" /></span>

            {{ $t('userProfile.actions.logoutCta') }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { routes } from 'routers/user-profile/routes';
import { shouldCloseOnClick } from 'utils/index';

export default {
  computed: {
    ...mapState('user', {
      user: 'activeUser'
    }),
    ...mapState('user', ['logoutUrl']),
    ...mapState('generic', ['userMenu', 'services']),
    ...mapGetters('user', ['isValidated', 'isInternal', 'canRequestSample', 'isPigmentEditor']),
    ...mapGetters('generic', ['userManagementLink']),
    routes() {
      const activeRoutes = [];

      routes.forEach((route) => {
        if (this.isInternal) {
          if (route.name === 'privacy') return;
        }

        // Hide sample requests entry if the active user is not allowed to request samples
        if (route.name === 'sampleRequests' && !this.canRequestSample) {
          return;
        }

        if (typeof route.showInNavigation !== 'undefined' || route.showInNavigation === false) {
          return;
        }

        activeRoutes.push(route);
      });

      return activeRoutes;
    },
    settingsRoutes() {
      return this.routes.filter((route) => route.navigationSection === 'settings');
    },
    dataRoutes() {
      return this.routes.filter((route) => route.navigationSection === 'data');
    },
    routerAvailable() {
      return !!this.$route;
    },
    staticRoutes() {
      return this.userMenu.staticLinks ? this.userMenu.staticLinks : [];
    }
  },
  mounted() {
    window.addEventListener('click', this.clickListener, false);
    window.addEventListener('touchend', this.clickListener, false);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickListener);
    window.removeEventListener('touchend', this.clickListener);
  },
  methods: {
    clickListener(e) {
      if (shouldCloseOnClick(e, 'ce-userMenuWrapper')) {
        this.$store.dispatch('user/hideUserMenu');
      }
    }
  }
};
</script>

<style lang="less">
  @import (reference) "~styles/includes/base";

  @bgColor: @color-mono-30;
  @color: @color-mono-100;

  .ce-pageHeader {
    .ce-userMenuWrapper {
      position: relative;

      .ce-userMenu {
        position: absolute;
        width: 260px;
        right: 0;
        top: 53px;
        background: @bgColor;
        padding: @space-3;
        color: @color;
        border-radius: @border-radius;

        &:before {
          content: "";
          width: 20px;
          height: 20px;
          background: @bgColor;
          position: absolute;
          top: -7px;
          right: 6px;
          transform: rotate(45deg);
        }

        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          .list-reset;

          li {
            padding: @space-2;
            margin: 0 !important;
            line-height: 1.5;
            .ms(-1);

            a {
              color: @color;
              display: flex;
              align-items: center;

              &:hover, &.router-link-exact-active {
                color: @color-light;
              }

              .icon {
                width: 32px;
                padding-right: 8px;
                display: flex;
                align-items: center;
                .flex-none;
              }
            }

            &:not(:last-child) {
              margin-bottom: @space-2;
              border-bottom: 1px solid lighten(@bgColor, 10);
            }
          }

          &:not(:first-of-type) {
            margin-top: @space-3;
            padding-top: @space-3;
            border-top: 2px solid lighten(@bgColor, 10);
          }
        }
      }
    }
  }
</style>
