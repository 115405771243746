var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.confirmed
    ? _c(
        "modal",
        {
          staticClass: "ce-requireCountryConfirmationModal",
          attrs: {
            headline: _vm.$t("requireCountryConfirmationModal.headline"),
            closable: false,
          },
        },
        [
          _c("div", { staticClass: "ce-modalContent" }, [
            _c("div", { staticClass: "line-height-4" }, [
              _c("div", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("requireCountryConfirmationModal.content")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bold" }, [
                _vm._v("\n        " + _vm._s(_vm.countryList) + "\n      "),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt4 flex flex-wrap mxn1" }, [
              _c(
                "button",
                {
                  staticClass: "ce-btn ce-btn-light m1",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.confirm.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        I confirm\n      ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "ce-btn ce-btn-grey m1", attrs: { href: "/" } },
                [_vm._v("\n        Decline\n      ")]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }