<template>
  <ValidationObserver
    ref="validationObserver"
    tag="div">
    <alert
      class="mb3"
      v-if="hasMessage"
      :message="message.content"
      :type="message.type"
      @close="message = {}" />

    <template v-if="!finished">
      <div
        v-for="field in fields"
        :key="field.key"
        class="ce-fieldWrapper"
        :class="[`ce-fieldWrapper--${field.type}`]">
        <template v-if="field.type === 'input'">
          <validated-input
            :name="field.key"
            :value="form[field.key]"
            @input="onInput"
            :label="field.label"
            :disabled="typeof field.disabled === 'boolean' ? field.disabled : false"
            :rules="field.rules ? field.rules : ''" />
        </template>

        <template v-if="field.type === 'select' && (!field.multi || field.multi !== true)">
          <validated-select
            :name="field.key"
            v-model="form[field.key]"
            :label="field.label"
            :disabled="typeof field.disabled === 'boolean' ? field.disabled : false"
            :rules="field.rules ? field.rules : ''"
            :options="field.options"
            :show-empty-default="field.emptyDefault"
          />
        </template>
        <template v-if="field.type === 'select' && field.multi === true">
          <validated-multiselect
            :name="field.key"
            v-model="form[field.key]"
            :label="field.label"
            :disabled="typeof field.disabled === 'boolean' ? field.disabled : false"
            :rules="field.rules ? field.rules : ''"
            :options="field.options"
            :show-empty-default="field.emptyDefault"
            :show-other-option="typeof field.showOtherOption === 'boolean' ? field.showOtherOption : false"
          />
        </template>

        <div
          v-if="field.type === 'label'"
          class="ce-field ce-labelField">
          {{ field.content }}
        </div>

        <div
          v-if="field.type === 'section_header'"
          class="ce-field ce-sectionHeader">
          {{ field.content }}
        </div>
      </div>

      <div
        v-if="consentHintNecessary"
        class="ms-1 line-height-4">
        <i18n
          path="form.consentHint"
          tag="p">
          <a
            :href="links.dataProtectionPage"
            rel="noopener noreferrer">
            {{ $t('form.dataProtectionPage') }}</a>
        </i18n>
      </div>

      <div class="mt4">
        <button
          class="ce-btn ce-btn-light ce-btn--fullWidth"
          @click.prevent="submit">
          <template v-if="isSending">
            <fa-icon
              icon="spinner"
              spin />
          </template>
          <template v-else>
            {{ $t('form.submitCta') }}
          </template>
        </button>
      </div>
    </template>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import axios from 'axios';
import Alert from 'components/Alert';

/*
  TODO:
   * component for table select / multiple choice like?!
   * two fields in one row?
 */
export default {
  props: {
    config: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isSending: false,
      message: {},
      form: {}
    };
  },
  components: { Alert },
  created() {
    this.reset();
  },
  computed: {
    ...mapState('user', {
      user: 'activeUser'
    }),
    ...mapState('generic', ['formSecurityToken', 'links']),
    consentHintNecessary() {
      return !(this.user
          && this.user.consent
          && Array.isArray(this.user.consent)
          && this.user.consent.indexOf('inquiries') > -1);
    },
    hasMessage() {
      return this.message && this.message.content;
    },
    fields() {
      const fields = this.$cloneObject(this.config.fields);
      fields.forEach((field) => {
        if (field.disabledRule) {
          // eslint-disable-next-line no-new-func
          const func = Function(field.disabledRule).bind(this);
          field.disabled = func();
        }
      });

      return fields;
    },
    submitPayload() {
      const data = { ...this.form };

      const payload = {};
      this.fields.forEach((field) => {
        const { key } = field;

        if (data[key] && data[key] !== '') {
          let value = data[key];

          if (field.type === 'select' && field.multi === true && Array.isArray(value)) {
            value = value.map((item) => (item.customInput ? item.customInput : item.value));
          }

          payload[key] = value;
        }
      });

      return payload;
    },
    finished() {
      return this.message && this.message.type === 'success';
    }
  },
  methods: {
    hasField(fieldKey) {
      return this.fields.find((field) => field.key === fieldKey);
    },
    reset() {
      this.isSending = false;
      this.form = {};

      this.fields.forEach((field) => {
        Vue.set(this.form, field.key, null);
      });

      if (this.$refs.validationObserver) {
        this.$refs.validationObserver.reset();
      }

      if (this.user) {
        ['email', 'firstName', 'lastName', 'company'].forEach((field) => {
          if (this.hasField(field) && this.user[field]) {
            this.form[field] = this.user[field];
          }
        });
      }
    },
    async submit() {
      const validationResult = await this.$refs.validationObserver.validate();
      if (!validationResult) return;
      this.isSending = true;

      const data = {
        formSecurityToken: this.formSecurityToken,
        formId: this.config.formId,
        form: {
          ...this.submitPayload
        }
      };

      try {
        await axios.post(`${location.origin}/api/v1/message/form`, data);
        this.$emit('finished');

        this.reset();

        this.message = {
          type: 'success',
          content: this.$t('contactForm.finishedMessage')
        };
      } catch (e) {
        this.message = {
          type: 'error',
          content: e.response?.data?.message ?? e.message
        };
      }

      this.isSending = false;
    }
  }
};
</script>

<style lang="less">
@import (reference) '~styles/includes/base';

.ce-fieldWrapper--label {
  &:not(:first-child) {
    margin-top: @space-5;
  }
}

.ce-fieldWrapper--section_header {
  .ms(3);
  font-weight: 700;
  &:not(:first-child) {
    margin-top: @space-5;
  }

  + .ce-fieldWrapper--label {
    margin-top: 0;
  }
}

.ce-fieldWrapper--spacer {
  margin-top: @space-5;
}
</style>
