import Vue from 'vue';
import CommonPlugin from 'plugins/common-plugin';
import { i18n } from 'utils/i18n';
import store from 'stores/generic';
import SearchField from 'components/product-search/SearchField';
import BlockPage from 'mixins/BlockPage';

Vue.use(CommonPlugin);

require('styles/pages/products.less');

new Vue({
  el: '#app',
  components: { SearchField },
  i18n,
  store,
  mixins: [BlockPage],
  methods: {
    search(term) {
      location.href = `${payload.searchPageLink}?q=${term}`;
    }
  }
});
