<template>
  <modal
    :headline="headline"
    class="ce-confirmationModal"
    :closable="false">
    <div class="ce-modalContent">
      <div
        class="ms1 line-height-3 mb4 center"
        v-if="description"
        v-html="description" />

      <div class="ce-confirmationModalActions">
        <button
          v-if="!disableReject"
          class="ce-btn ce-btn-grey"
          @click.prevent="doReject">
          {{ rejectCtaLabel }}
        </button>
        <button
          class="ce-btn ce-btn-danger"
          @click.prevent="doResolve">
          {{ resolveCtaLabel }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import { i18n } from 'utils/i18n';

export default Vue.extend({
  i18n,
  props: {
    headline: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    resolveCta: {
      type: String,
      required: false,
      default: null
    },
    rejectCta: {
      type: String,
      required: false,
      default: null
    },
    resolve: {
      type: Function,
      required: true
    },
    reject: {
      type: Function,
      required: false,
      default: null
    },
    disableReject: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    resolveCtaLabel() {
      return this.resolveCta ? this.resolveCta : this.$t('confirmationModal.defaultResolveCta');
    },
    rejectCtaLabel() {
      return this.rejectCta ? this.rejectCta : this.$t('confirmationModal.defaultRejectCta');
    }
  },
  methods: {
    doResolve() {
      this.resolve();
      this.destroy();
    },
    doReject() {
      this.reject();
      this.destroy();
    },
    destroy() {
      this.$destroy();
      this.$el.remove();
    }
  }
});

</script>

<style lang="less">
  @import (reference) "~styles/includes/base";

  .ce-confirmationModal {
    .ce-modalContent {
      .center {
        ul {
          display: inline-block;
          margin: 0;
          text-align: left;
        }
      }
    }

    .ce-confirmationModalActions {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .mxn1;

      > button {
        margin: @space-1;
      }
    }
  }
</style>
