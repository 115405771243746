import Cookies from 'js-cookie';
import { addMonths } from 'date-fns';
import qs from 'qs';
import PigmentService from 'services/PigmentService';
import { cloneObject, requireConfirmation } from 'utils/index';
import { translate } from 'utils/i18n';
import * as types from './mutation-types';

export default {
  async newComparison({ getters, commit, dispatch }, data = {}) {
    if (getters.isUnsavedComparisonActive) {
      await requireConfirmation(
        translate('productComparison.discardUnsavedComparisonWarning.headline'),
        {
          description: translate('productComparison.discardUnsavedComparisonWarning.description')
        },
        this
      );
    }

    commit(types.NEW_COMPARISON, {
      title: data.title ? data.title : '',
      industry: data.industry ? data.industry : ''
    });
    dispatch('saveToSession');
  },
  async setIndustry({ state, commit, dispatch }, industry) {
    if (!state.activeComparison) {
      await dispatch('newComparison');
    }

    commit(types.SET_INDUSTRY, industry);
    dispatch('saveToSession');
  },
  async showBasketIndustryModal({ commit }, {
    pigment, industry, showHint
  }) {
    let resolvePromise = null;
    const tempPromise = new Promise((resolve) => {
      resolvePromise = resolve;
    });

    commit(types.SET_ADD_TO_BASKET_TEMP, {
      pigment,
      industry,
      showHint,
      resolvePromise
    });

    return tempPromise;
  },
  async addToBasket({
    state, dispatch, commit
  }, { pigment, industry }) {
    if (!state.activeComparison) {
      dispatch('newComparison');
    }

    const setHintCookie = () => {
      Cookies.set('ce-comparisonIndustryHintSeen', true, {
        expires: addMonths(new Date(), 18),
        secure: process.env.NODE_ENV !== 'development',
      });
    };

    if (!state.activeComparison.industry) {
      const showHint = !Cookies.get('ce-comparisonIndustryHintSeen') || JSON.parse(Cookies.get('ce-comparisonIndustryHintSeen')) !== true;

      if (industry) {
        if (showHint) {
          // Wait until the user closes the info modal
          await dispatch('showBasketIndustryModal', {
            pigment, industry, showHint
          });

          setHintCookie();
        }

        dispatch('setIndustry', industry);
        commit(types.ADD_TO_BASKET, { id: pigment.id, prd: pigment.prd, product_name: pigment.product_name });
        dispatch('openBasket');
        dispatch('saveToSession');
      } else {
        // Show modal and await industry selection
        const selectedIndustry = await dispatch('showBasketIndustryModal', {
          pigment, industry, showHint
        });

        setHintCookie();

        dispatch('setIndustry', selectedIndustry);
        commit(types.ADD_TO_BASKET, { id: pigment.id, prd: pigment.prd, product_name: pigment.product_name });
        dispatch('openBasket');
        dispatch('saveToSession');
      }
    } else {
      // There is already an active comparison including industry, so just add the product
      commit(types.ADD_TO_BASKET, { id: pigment.id, prd: pigment.prd, product_name: pigment.product_name });
      dispatch('openBasket');
      dispatch('saveToSession');
    }
  },
  clearAddToBasketTemp({ commit }) {
    commit(types.SET_ADD_TO_BASKET_TEMP, null);
  },
  removeProduct({ commit, dispatch, getters }, productId) {
    commit(types.REMOVE_PRODUCT, productId);

    // Reset industry when the last product was removed
    if (getters.basket.length === 0) {
      dispatch('newComparison');
    }

    dispatch('saveToSession');
  },
  saveToSession({ state }) {
    const comparison = cloneObject(state.activeComparison);

    // Remove product detail infos
    delete comparison.products;

    Cookies.set(state.activeComparisonSessionKey, JSON.stringify(comparison), {
      secure: process.env.NODE_ENV !== 'development',
    });
  },
  clearSession({ state }) {
    Cookies.remove(state.activeComparisonSessionKey);
  },
  async save({ getters, dispatch, commit }, comparison) {
    let result;
    if (typeof comparison.id !== 'undefined') {
      result = await getters.apiClient.put(`/${comparison.id}`, comparison);
    } else {
      result = await getters.apiClient.post('/', comparison);
    }

    commit(types.UPDATE_ACTIVE_COMPARISON, result.data);
    dispatch('saveToSession');
  },
  async loadComparison({ getters, commit, dispatch }, id) {
    if (getters.isUnsavedComparisonActive) {
      await requireConfirmation(
        translate('productComparison.discardUnsavedComparisonWarning.headline'),
        {
          description: translate('productComparison.discardUnsavedComparisonWarning.description')
        },
        this
      );
    }

    const response = await getters.apiClient.get(`/${id}`);

    commit(types.SET_ACTIVE_COMPARISON, response.data);
    dispatch('saveToSession');
  },
  async fetchComparisons({ getters }) {
    return (await getters.apiClient.get('/')).data;
  },
  async deleteComparison({ dispatch }, id = null) {
    await requireConfirmation(
      translate('productComparison.deleteComparisonWarning.headline'),
      {
        description: translate('productComparison.deleteComparisonWarning.description')
      },
      this
    );

    dispatch('forceDeleteComparison', id);
  },
  async forceDeleteComparison({
    getters, state, commit, dispatch
  }, id = null) {
    if (id) {
      await getters.apiClient.delete(`/${id}`);
    }

    if (state.activeComparison
      && (!id || (state.activeComparison.id && state.activeComparison.id === id))) {
      commit(types.SET_ACTIVE_COMPARISON, null);
      dispatch('clearSession');
      dispatch('closeBasket');
    }
  },
  async fetchProductInfos({ state, commit, rootGetters }) {
    const ids = state.activeComparison.basket
      .map((item) => parseInt(item.id));

    const { industry } = state.activeComparison;

    const result = await PigmentService.fetchByIds(
      ids,
      industry,
      rootGetters['user/token'],
      true
    );

    commit(types.SET_PRODUCTS, result);
  },
  openBasket({ commit }) {
    commit(types.SET_BASKET_OPEN_STATE, true);
  },
  closeBasket({ commit }) {
    commit(types.SET_BASKET_OPEN_STATE, false);
  },
  toggleBasket({ commit, state }) {
    commit(types.SET_BASKET_OPEN_STATE, !state.basketOpen);
  },
  disableBasket({ commit }) {
    commit(types.SET_BASKET_DISABLED_STATE, true);
  },
  enableBasket({ commit }) {
    commit(types.SET_BASKET_DISABLED_STATE, false);
  },
  exportPdf({ dispatch, rootState }, comparison) {
    dispatch('generic/trackEvent', {
      category: 'Product Comparison',
      action: 'ProdCompExportPDF',
      name: {
        prds: comparison.basket.map((p) => p.prd),
        industry: comparison.industry
      }
    }, { root: true });

    const params = {
      i: comparison.industry,
      products: comparison.basket.map((p) => p.id),
      download: true
    };

    if (comparison.id && comparison.title) {
      params.title = comparison.title;
    }

    if (rootState.user.activeUser) {
      params.token = rootState.user.activeUser.token;
    }

    location.href = `${rootState.generic.services.backend.url}/util/download/comparison?${qs.stringify(params, { arrayFormat: 'comma', encode: false })}`;
  }
};
