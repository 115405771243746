var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ce-field ce-multiSelectField",
      class: { "ce-multiSelectField--withOtherOption": _vm.otherOptionActive },
    },
    [
      _c("ValidationProvider", {
        attrs: {
          name: _vm.validatorName,
          rules: _vm.rules,
          mode: _vm.mode,
          tag: "div",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _vm.label
                  ? _c("label", { attrs: { for: _vm.name } }, [
                      _vm._v("\n      " + _vm._s(_vm.label) + " "),
                      _vm.isRequired && _vm.showRequiredIndicator
                        ? _c("span", [_vm._v("*")])
                        : _vm._e(),
                      _vm._v(" "),
                      errors.length > 0
                        ? _c(
                            "span",
                            { staticClass: "ms-2 ml1 regular ce-colorError" },
                            [
                              _vm._v(
                                "\n        " + _vm._s(errors[0]) + "\n      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "ce-multiSelectField-fieldWrapper" }, [
                  _c(
                    "div",
                    [
                      _c("multiselect", {
                        attrs: {
                          id: _vm.name,
                          name: _vm.name,
                          value: _vm.value,
                          options: _vm.preparedOptions,
                          label: "title",
                          "track-by": "value",
                          multiple: true,
                          "preserve-search": false,
                          "hide-selected": true,
                          placeholder: "",
                          disabled: _vm.disabled,
                        },
                        on: { input: _vm.handleInput },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.otherOptionActive
        ? _c("ValidationProvider", {
            attrs: {
              name: _vm.validatorName,
              rules: _vm.otherRules,
              mode: _vm.mode,
              tag: "div",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _vm.label
                        ? _c("label", [
                            _vm._v(
                              "\n      " +
                                _vm._s(_vm.$t("generic.otherOption")) +
                                " "
                            ),
                            _vm.isOtherRequired && _vm.showRequiredIndicator
                              ? _c("span", [_vm._v("*")])
                              : _vm._e(),
                            _vm._v(" "),
                            errors.length > 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ms-2 ml1 regular ce-colorError",
                                  },
                                  [
                                    _vm._v(
                                      "\n        " +
                                        _vm._s(errors[0]) +
                                        "\n      "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "text",
                          disabled: _vm.disabled,
                          autocomplete: "",
                          autocapitalize: "",
                        },
                        domProps: { value: _vm.other },
                        on: {
                          input: function ($event) {
                            return _vm.handleCustomInput($event.target.value)
                          },
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              2823356437
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }