var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "ce-userLogin",
      attrs: {
        headline: _vm.$t("login.modal.headline"),
        description: _vm.$t("login.modal.description"),
      },
      on: { close: _vm.close },
    },
    [_c("div", { staticClass: "ce-modalContent" }, [_c("UserLogin")], 1)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }