var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validated-select", {
    attrs: {
      name: _vm.name,
      label: _vm.label,
      options: _vm.options,
      value: _vm.value,
      rules: _vm.rules,
      disabled: _vm.disabled,
      searchable: "",
      loading: _vm.countriesLoading,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }