import qs from 'qs';
import SearchConfig from '@sun-chemical/system-config/search-config';
import { cloneObject } from './index';

/**
 * Parse the parameters of the current url.
 *
 * @return {{}}
 */
const parseUrlFilters = () => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true, comma: true });

  Object.keys(params).forEach((key) => {
    let val = params[key];
    if (typeof val === 'string') {
      if (val === 'true') val = true;
      else if (val === 'false') val = false;
    }

    params[key] = val;
  });

  return params;
};

/**
 * Update/Set the url based on the given parameters.
 *
 * @param params
 * @return {{newUrl: string, urlParams: string}}
 */
const updateUrlByParams = (params) => {
  const encodedParams = cloneObject(params);
  const termParam = SearchConfig.getMap().search_term.param;

  if (encodedParams && encodedParams[termParam]) {
    encodedParams[termParam] = encodeURIComponent(encodedParams[termParam]);
  }

  let urlParams = qs.stringify(encodedParams, { arrayFormat: 'comma', encode: false });

  let newUrl;

  // Remove all params when there are no params
  if (!urlParams) {
    newUrl = location.href.replace(location.search, '');
  } else {
    urlParams = `?${qs.stringify(encodedParams, { arrayFormat: 'comma', encode: false })}`;
    newUrl = location.search
      ? location.href.replace(location.search, urlParams)
      : `${location.href}${urlParams}`;
  }

  history.replaceState({}, null, newUrl);

  return { newUrl, urlParams };
};

const getUrlParams = () => qs.parse(location.search, { ignoreQueryPrefix: true, comma: true });

const getUrlParam = (param) => {
  const params = getUrlParams();

  return params && typeof params[param] !== 'undefined' ? params[param] : null;
};

const removeUrlParam = (param) => {
  const params = getUrlParams();
  delete params[param];

  let url;

  // no params left, so remove the whole search part
  if (Object.keys(params).length === 0) {
    url = location.href.replace(location.search, '');
  } else {
    const paramsString = `?${qs.stringify(params, { arrayFormat: 'comma', encode: false })}`;
    url = location.search
      ? location.href.replace(location.search, paramsString)
      : `${location.href}${paramsString}`;
  }

  history.replaceState({}, null, url);
};

export {
  parseUrlFilters, updateUrlByParams, getUrlParams, getUrlParam, removeUrlParam
};
