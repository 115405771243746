<template>
  <transition
    name="fade"
    enter-active-class="fadeInUp"
    leave-active-class="fadeOutDown">
    <div
      class="ce-backToTopButton"
      :class="{'ce-backToTopButton--secondary': isFloatingActionVisible}"
      v-show="visible"
      @click.prevent="scrollTop">
      <fa-icon icon="arrow-up" />
      {{ $t('generic.backToTopCta') }}
    </div>
  </transition>
</template>

<script>
import Velocity from 'velocity-animate';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      visible: false,
      activeBreakpoint: null
    };
  },
  props: {
    breakpoint: {
      type: Number,
      default: null
    },
    duration: {
      type: Number,
      default: 400
    }
  },
  mounted() {
    this.activeBreakpoint = this.breakpoint ? this.breakpoint : this.getWindowHeight();

    this.addListener();
    this.checkPosition();
  },
  computed: {
    ...mapGetters('generic', ['isFloatingActionVisible'])
  },
  methods: {
    getWindowHeight() {
      return window.innerHeight
          || document.documentElement.clientHeight
          || document.body.clientHeight;
    },
    addListener() {
      this.$nextTick(() => {
        window.addEventListener('scroll', this.checkPosition, false);
      });
    },
    checkPosition() {
      if (window.pageYOffset > this.activeBreakpoint && !this.visible) {
        this.visible = true;
      } else if (window.pageYOffset < this.activeBreakpoint && this.visible) {
        this.visible = false;
      }
    },
    scrollTop() {
      this.$emit('scroll-start');
      Velocity(document.documentElement, 'scroll', {
        duration: this.duration,
        complete: () => {
          this.$emit('scroll-complete');
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) '~styles/includes/base';

.ce-backToTopButton {
  position: fixed;
  z-index: 6990; // right below comparison basket
  bottom: @space-3;
  right: @space-3;
  padding: @space-2 @space-3;
  background: @color-light;
  border: 1px solid @color-mono-100;
  color: @color-mono-100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: @border-radius;
  cursor: pointer;
  transition: all 250ms ease-in-out;

  &.ce-backToTopButton--secondary {
    right: (@space-3 + 50px + @space-3);
    bottom: (@space-3 + ((50px - 36px) / 2)); // 50px = height floating actions (basket toggle), 36px = height backToTopButton
  }

  &:hover {
    background: @color-main;
  }

  svg {
    margin-right: @space-2;
  }
}
</style>
