var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "modal",
    {
      staticClass: "ce-confirmationModal",
      attrs: { headline: _vm.headline, closable: false },
    },
    [
      _c("div", { staticClass: "ce-modalContent" }, [
        _vm.description
          ? _c("div", {
              staticClass: "ms1 line-height-3 mb4 center",
              domProps: { innerHTML: _vm._s(_vm.description) },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "ce-confirmationModalActions" }, [
          !_vm.disableReject
            ? _c(
                "button",
                {
                  staticClass: "ce-btn ce-btn-grey",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doReject.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.rejectCtaLabel) + "\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "ce-btn ce-btn-danger",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.doResolve.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.resolveCtaLabel) + "\n      ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }