export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    isRootLevel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      subNavVisible: false
    };
  },
  computed: {
    hasSubNav() {
      return this.isRootLevel
                && this.item.children
                && Array.isArray(this.item.children)
                && this.item.children.length > 0;
    }
  },
};
