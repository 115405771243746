import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState('generic', ['mobileMainMenu']),
    ...mapState('user', ['activeUser']),
    ...mapGetters('user', ['isValidated', 'isInternal', 'canViewFormulations']),
    menuItems() {
      let items = this.mobileMainMenu;

      if (this.activeUser) {
        // user is not internal, remove all internal_users only pages
        if (!this.isInternal) {
          items = items.filter((p) => p.accessRestriction !== 'internal_users');
        }

        // user is not validated (and also not internal), remove validated_users only pages
        if (!this.isValidated && !this.isInternal) {
          items = items.filter((p) => p.accessRestriction !== 'validated_users');
        }
      } else { // no logged-in user, remove product sheets page and each page with a accessRestriction
        items = items.filter((p) => {
          if (p.class === 'CE\\ServicePlatform\\ProductSheetsPage') {
            return false;
          }

          if (p.accessRestriction && p.accessRestriction !== 'none') {
            return false;
          }

          return true;
        });
      }

      return items;
    }
  }
};
