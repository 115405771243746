<template>
  <nav class="ce-mainNav">
    <ul class="list-reset">
      <menu-item
        v-for="rootPage in menuItems"
        :key="rootPage.id"
        :item="rootPage"
        :top-offset="subNavTopOffset"
      />
    </ul>
  </nav>
</template>

<script>
import Menu from 'mixins/Menu';
import MenuItem from './MenuItem.vue';

export default {
  components: { MenuItem },
  mixins: [Menu],
  data() {
    return {
      subNavTopOffset: 50
    };
  },
  mounted() {
    this.subNavTopOffset = this.$el.clientHeight;
  }
};
</script>

<style lang="less">
@import (reference) "~styles/includes/base";

.ce-mainNav {
  position: relative;
}
</style>
