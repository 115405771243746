import logger from 'utils/logger';
import { cloneObject } from 'utils/index';
import UserService from 'services/UserService';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const namespaced = true;

const state = {
  activeUser: cloneObject(payload.user),
  loginVisible: payload.generic.showLogin ? payload.generic.showLogin : false,
  registrationVisible: payload.generic.showRegistration ? payload.generic.showRegistration : false,
  forgotPasswordVisible: false,
  userMenuVisible: false,
  logoutUrl: payload.logoutUrl,
  deleteAccountUrl: payload.deleteAccountUrl,
  userService: new UserService(),
};

try {
  // Add the possibility to show the login form using a session storage entry, used e.g. for the password reset flow
  if (sessionStorage.getItem('ce-showLogin') && sessionStorage.getItem('ce-showLogin') === 'true') {
    state.loginVisible = true;
    sessionStorage.removeItem('ce-showLogin');
  }
} catch (e) {
  // noop
}

const init = (store) => {
  const xhrSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function (...args) {
    this.addEventListener('load', function () {
      const isInternalUrl = (url) => {
        const match = ['colors-effects', 'colormaterials.sunchemical', 'localhost']
          .find((internalUrl) => url.indexOf(internalUrl) > -1);

        return !!match;
      };

      const jwtHeaderAvailable = () => this.getAllResponseHeaders().indexOf('jwt') > -1;

      if (this.responseURL && isInternalUrl(this.responseURL) && jwtHeaderAvailable()) {
        const responseJwt = this.getResponseHeader('jwt');
        if (
          responseJwt
          && store
          && store.state
          && store.state.user
          && store.state.user.activeUser
          && store.state.user.activeUser.token
          && store.state.user.activeUser.token !== responseJwt) {
          store.dispatch('user/updateUserToken', responseJwt);
        }
      }
    });

    return xhrSend.apply(this, args);
  };

  if (state.activeUser) {
    state.userService.setUser(state.activeUser);
    logger.setUser(state.activeUser.userId);
  }
};

export {
  getters, actions, mutations, state, init
};
