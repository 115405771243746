<template>
  <modal
    v-if="!confirmed"
    :headline="$t('requireCountryConfirmationModal.headline')"
    class="ce-requireCountryConfirmationModal"
    :closable="false">
    <div class="ce-modalContent">
      <div class="line-height-4">
        <div>
          {{ $t('requireCountryConfirmationModal.content') }}
        </div>
        <div class="bold">
          {{ countryList }}
        </div>
      </div>

      <div class="mt4 flex flex-wrap mxn1">
        <button
          class="ce-btn ce-btn-light m1"
          @click.prevent="confirm">
          I confirm
        </button>

        <a
          href="/"
          class="ce-btn ce-btn-grey m1">
          Decline
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      confirmed: false
    };
  },
  computed: {
    countryList() {
      return Object.values(this.payload.allowedCountries).join(', ');
    }
  },
  methods: {
    confirm() {
      this.confirmed = true;
    }
  }
};
</script>
