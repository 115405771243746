import Vue from 'vue';
import Vuex from 'vuex';
import * as user from '../modules/user';
import * as generic from '../modules/generic';
import * as productComparison from '../modules/product-comparison';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    generic,
    productComparison
  }
});

user.init(store);
generic.init(store);

export default store;
