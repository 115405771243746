<template>
  <ValidationObserver
    ref="passwordValidationObserver"
    class="ce-passwordField"
    tag="div"
    v-slot="{ errors: observerErrors }">
    <ValidationProvider
      :name="validatorName"
      v-slot="{ errors }"
      rules="required|password"
      mode="eager"
      tag="div"
      class="ce-field">
      <label
        v-if="label"
        :for="name">
        {{ label }} <span v-if="showRequiredIndicator">*</span>
      </label>

      <div
        class="ce-field-inputWrapper"
        :class="{ invalid: errors.length > 0 }">
        <input
          ref="input"
          :id="name"
          type="password"
          :name="name"
          :value="value"
          :disabled="disabled"
          autocomplete="off"
          autocapitalize="off"
          @input="$emit('input', $event)"
          v-on="$listeners">
      </div>
    </ValidationProvider>

    <ValidationProvider
      v-slot="{ errors }"
      :rules="`required|passwordconfirmation:@${validatorName}`"
      :name="$t('validation.password.confirmationLabel', { fieldLabel: validatorName })"
      mode="aggressive"
      tag="div"
      class="ce-field">
      <label
        v-if="label"
        :for="name">
        {{ $t('validation.password.confirmationLabel', { fieldLabel: label }) }} <span v-if="showRequiredIndicator">*</span>
        <span
          v-if="errors.length > 0"
          class="ms-2 ml1 regular ce-colorError">
          {{ errors[0] }}
        </span>
      </label>

      <div
        class="ce-field-inputWrapper"
        :class="{ invalid: errors.length > 0 }">
        <input
          :id="`${name}_confirmation`"
          type="password"
          :name="`${name}_confirmation`"
          :disabled="disabled"
          autocomplete="off"
          autocapitalize="off"
          v-model="confirmation">
      </div>
    </ValidationProvider>

    <div
      class="ce-passwordField-description"
      :class="{'ce-colorError': observerErrors[validatorName] && Array.isArray(observerErrors[validatorName]) && observerErrors[validatorName].length > 0}">
      {{ $t('validation.password.criteriaMessage') }}
    </div>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },

    name: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    validatorName: {
      type: String,
      required: false,
      default() {
        return this.label;
      }
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    showRequiredIndicator: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      confirmation: null
    };
  },
  methods: {
    async validate() {
      return this.$refs.passwordValidationObserver.validate();
    },
    async reset() {
      this.confirmation = null;
      return this.$refs.passwordValidationObserver.reset();
    }
  }
};
</script>

<style lang="less">
@import (reference) '~styles/includes/base';

.ce-passwordField {
  .ce-passwordField-description {
    margin: @space-3 0;
    .ms(-2);
    .line-height-3;

    &.ce-colorError {
      font-weight: bold;
    }
  }
}
</style>
