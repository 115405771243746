<template>
  <div
    class="ce-changedProductRow"
    :class="{'ce-changedProductRow--open': isOpen}">
    <div
      class="ce-changedProductRow-header"
      @click="isOpen = !isOpen">
      <div class="ce-changedProductRow-toggle">
        <a
          href=""
          @click.prevent="">
          <fa-icon icon="angle-down" />
        </a>
      </div>

      <div>
        {{ product.product_name }}
      </div>
    </div>

    <transition
      :css="false"
      @enter="enterAnimation"
      @leave="leaveAnimation">
      <div
        v-if="isOpen"
        class="ce-changedProductRow-assets">
        <div
          v-for="asset in product.assets"
          :key="asset.id">
          {{ manifest[asset.type] }} v.{{ asset.version }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import slideAnimation from 'mixins/slideAnimation';

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    manifest: {
      type: Object,
      required: true
    }
  },
  mixins: [slideAnimation],
  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style lang="less">
@import (reference) "~styles/includes/base";

.ce-changedProductRow {
  .ce-changedProductRow-header {
    transition: all 250ms ease-in-out;
    padding: @space-3;
    .noselect();
    cursor: pointer;
    display: flex;

    .ce-changedProductRow-toggle {
      margin-right: @space-2;

      svg {
        transition: all 250ms ease-in-out;
      }
    }
  }

  &.ce-changedProductRow--open {
    .ce-changedProductRow-toggle {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .ce-changedProductRow-assets {
    padding-left: 32px;
    .ms(-1);

    > div {
      padding: @space-3 @space-3 @space-3 0;

      &:not(:last-child) {
        border-bottom: 1px solid @color-mono-80;
      }
    }
  }

  div &:not(:last-child) {
    .ce-changedProductRow-header {
      border-bottom: 1px solid @color-mono-80;
    }
  }

  div &:nth-child(2n + 1) {
    background: @color-mono-92;
  }
}
</style>
