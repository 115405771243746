var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ce-checkbox",
      class: [
        { disabled: _vm.disabled },
        { checked: _vm.value },
        { centered: _vm.verticalCentered },
      ],
    },
    [
      _c("input", {
        attrs: { type: "checkbox", disabled: _vm.disabled, id: _vm.id },
        domProps: { checked: _vm.value },
        on: {
          change: function ($event) {
            return _vm.toggle($event.target.checked)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        { class: { "no-label": !_vm.showLabel }, attrs: { for: _vm.id } },
        [
          _vm.showLabel
            ? _vm._t("default", function () {
                return [_vm._v("Label")]
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "checkbox" },
        [
          _c("fa-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.value,
                expression: "value",
              },
            ],
            attrs: { icon: ["fas", "check"] },
          }),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.value && _vm.partiallyChecked,
                expression: "!value && partiallyChecked",
              },
            ],
            staticClass: "ce-checkbox-partialHint",
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }