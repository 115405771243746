import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import pkg from 'root/package.json';

const config = payload.sentry;
const useSentry = config.enabled;

/**
 * Logging helper class, using Sentry on production.
 *
 * @see https://docs.sentry.io/platforms/javascript/vue/
 */
export default {
  init() {
    if (useSentry) {
      Sentry.init({
        dsn: config.dsn,
        release: `${pkg.name}@${pkg.version}`,
        environment: config.environment,
        integrations: [
          new Integrations.Vue({
            Vue,
            attachProps: true,
          }),
          new Integrations.ExtraErrorData()
        ]
      });
    }
  },
  // ATTENTION: Called in the stores/modules/user/action.js file in the "setUser" method and in the init method of the user store module
  // so remove it there if we do not need it later on
  setUser(userId) {
    // TODO disabled for privacy reasons, maybe we could use if the user accepts "marketing" cookies?!
    /* Sentry.configureScope((scope) => {
      scope.setUser(userId);
    }); */
  },
  captureException(err) {
    if (useSentry) {
      Sentry.captureException(err);
    } else {
      console.log(err);
    }
  }
};
