var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ce-userLogin-content" },
    [
      _vm.verificationEmailSent
        ? _c("div", { staticClass: "line-height-3" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("login.verificationEmail.sentMessage")) +
                "\n  "
            ),
          ])
        : [
            _vm.hasMessage
              ? _c(
                  "alert",
                  { attrs: { type: _vm.message.type, closable: false } },
                  [
                    _c("div", [
                      _c(
                        "p",
                        { staticClass: "my0" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.message.content) +
                              "\n\n          "
                          ),
                          _vm.message.code &&
                          _vm.message.code === "email_not_verified"
                            ? _c(
                                "i18n",
                                {
                                  attrs: {
                                    path: "login.verificationEmail.sendNewMessage",
                                    tag: "span",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.resendVerificationEmail.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "login.verificationEmail.sendNewCta"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.twoFactorAuthActive
              ? [
                  _c(
                    "ValidationObserver",
                    { ref: "validationObserver", attrs: { tag: "div" } },
                    [
                      _c("validated-input", {
                        ref: "emailInputField",
                        attrs: {
                          name: "email",
                          value: _vm.form.email,
                          label: _vm.$t("login.email"),
                          autocapitalize: "none",
                          rules: "required|email",
                        },
                        on: { input: _vm.onInput },
                      }),
                      _vm._v(" "),
                      _c("validated-input", {
                        attrs: {
                          name: "password",
                          value: _vm.form.password,
                          label: _vm.$t("login.password"),
                          rules: "required",
                          type: "password",
                        },
                        on: {
                          input: _vm.onInput,
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "ce-btn ce-btn-light ce-btn--fullWidth",
                            attrs: { disabled: _vm.loading },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm.loading
                              ? [
                                  _c("fa-icon", {
                                    attrs: { icon: "spinner", spin: "" },
                                  }),
                                ]
                              : [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("login.loginCta")) +
                                      "\n            "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasSubActions
                    ? _c(
                        "div",
                        { staticClass: "mt2 ce-userLogin-subActions" },
                        [
                          _vm.showRegisterCta
                            ? _c(
                                "div",
                                { staticClass: "ce-userLogin-register" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("login.noAccount")) +
                                      " "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: _vm.registerLink },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.$store.dispatch(
                                            "user/showRegistration"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("login.registerCta"))
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showForgotPasswordCta
                            ? _c("div", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: _vm.loginLink },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.$store.dispatch(
                                          "user/showForgotPassword"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("login.forgotPasswordCta")
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }