import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import VueYouTubeEmbed from 'vue-youtube-embed';
import DynamicForm from 'components/forms/DynamicForm';
import ExtendableContent from 'components/ExtendableContent';

Vue.use(VueCarousel);
Vue.use(VueYouTubeEmbed);
Vue.component('dynamic-form', DynamicForm);
Vue.component('extendable-content', ExtendableContent);

export default {
  computed: {
    carouselBreakpoints() {
      return [[600, 2], [1024, 3]];
    }
  },
  methods: {
    getYoutubeIdFromUrl(url) {
      return this.$youtube.getIdFromURL(url);
    }
  }
};
