<template>
  <div class="ce-blockContent">
    <transition
      :css="false"
      @enter="enterAnimation"
      @leave="leaveAnimation">
      <div v-if="!isOpen">
        <a
          href=""
          @click.prevent="toggle">
          {{ ctaLabel }}
        </a>
      </div>
    </transition>

    <transition
      :css="false"
      @enter="enterAnimation"
      @leave="leaveAnimation">
      <div v-if="isOpen">
        <div v-html="content" />
        <div v-if="showCloseCta">
          <a
            href=""
            @click.prevent="toggle">
            {{ payload.closeCta }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import slideAnimation from 'mixins/slideAnimation';

export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  mixins: [slideAnimation],
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    ctaLabel() {
      return this.payload.cta;
    },
    content() {
      return this.payload.content;
    },
    showCloseCta() {
      return !!this.payload.closeCta;
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    }
  }
};
</script>
