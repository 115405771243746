import axios from 'axios';

const apiClient = axios.create({
  baseUrl: payload.services.backend.url
});

export default {
  async getFormulationAttachmentDownloadUri(formulationId, attachmentId, token = null) {
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return (
      await apiClient.get(
        `/api/v1/formulation/${formulationId}/attachment/${attachmentId}/download`,
        config
      )
    ).data;
  },
  async getCountryList() {
    return (await apiClient.get('/api/v1/country')).data;
  }
};
