var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.basketDisabled && _vm.isComparisonActive
    ? _c(
        "div",
        {
          staticClass: "ce-comparisonBasket",
          class: { "ce-comparisonBasket--open": _vm.basketOpen },
        },
        [
          _c(
            "transition",
            {
              attrs: {
                name: "slide",
                "enter-active-class": "slideInRight",
                "leave-active-class": "slideOutRight",
              },
            },
            [
              _vm.basketOpen
                ? _c("div", { staticClass: "ce-comparisonBasket-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ce-comparisonBasket-contentInner ce-container",
                      },
                      [
                        _vm.activeComparisonIndustry
                          ? _c(
                              "div",
                              { staticClass: "my2" },
                              [
                                _c(
                                  "i18n",
                                  {
                                    attrs: {
                                      path: "productComparison.basket.headline",
                                      tag: "span",
                                    },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.activeComparisonIndustry.title
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "ms-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "productComparison.basket.minMaxHint"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-end ce-comparisonBasket-productsAndActionContainer",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ce-comparisonBasket-productsWrapper",
                              },
                              [
                                _vm._l(_vm.basket, function (product, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: product.id,
                                      staticClass:
                                        "center ce-comparisonBasket-product",
                                    },
                                    [
                                      _c("div", [
                                        _c("strong", [
                                          _vm._v(_vm._s(index + 1) + "."),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ce-comparisonBasketProduct-title",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(product.product_name) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ce-comparisonBasketProduct-deleteCta",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.removeProduct(
                                                  product.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("fa-icon", {
                                              staticClass: "ms-3",
                                              attrs: { icon: "times" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _vm.basket.length === 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ce-comparisonBasket-addMoreHint",
                                      },
                                      [
                                        _c("fa-icon", {
                                          staticClass: "mr2",
                                          attrs: { icon: "info-circle" },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "productComparison.basket.addMoreHint"
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ce-comparisonBasket-actionsWrapper pl4",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "ce-btn ce-btn-light",
                                    attrs: {
                                      disabled: _vm.basket.length < 2,
                                      href: _vm.links.comparePage,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "productComparison.basket.compareCta"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ce-comparisonBasket-toggle" }, [
            _c(
              "button",
              {
                staticClass: "ce-btn ce-btn-light",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleBasket.apply(null, arguments)
                  },
                },
              },
              [
                _vm.basketOpen
                  ? _c("fa-icon", { attrs: { icon: "times" } })
                  : _c(
                      "fa-layers",
                      [
                        _c("fa-icon", { attrs: { icon: "list" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "fa-layers-counter" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.basket.length) +
                              "\n        "
                          ),
                        ]),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }