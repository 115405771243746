export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const NEW_COMPARISON = 'NEW_COMPARISON';
export const SET_INDUSTRY = 'SET_INDUSTRY';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_BASKET_OPEN_STATE = 'SET_BASKET_OPEN_STATE';
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const SET_BASKET_DISABLED_STATE = 'SET_BASKET_DISABLED_STATE';
export const SET_ADD_TO_BASKET_TEMP = 'SET_ADD_TO_BASKET_TEMP';
export const SET_ACTIVE_COMPARISON = 'SET_ACTIVE_COMPARISON';
export const UPDATE_ACTIVE_COMPARISON = 'UPDATE_ACTIVE_COMPARISON';
