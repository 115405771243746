<template>
  <div class="ce-searchField">
    <vue-simple-suggest
      v-model="term"
      :list="suggest"
      :remove-list="suggestionsDisabled"
      display-attribute="product_name"
      :debounce="400"
      :destyled="false"
      @select="selected"
      ref="suggestField"
      :prevent-submit="false"
      @show-list="listShown">
      <input
        :placeholder="$t('searchField.placeholder')"
        type="text"
        name="term"
        :value="term"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        @keydown.enter="enter">
    </vue-simple-suggest>

    <div class="ms-2 mt2 pl2">
      <template v-if="onSearchPage">
        {{ $t('searchField.hintOnSerp') }}
      </template>
      <template v-else>
        {{ $t('searchField.hintGeneric') }}
      </template>
    </div>

    <button
      type="submit"
      @click.prevent="enter">
      <fa-icon :icon="['fas', 'search']" />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueSimpleSuggest from 'vue-simple-suggest/dist/cjs'; // Use commonJS build for ie compatibility
import PigmentService from 'services/PigmentService';
import { sortArray } from 'utils';
import ProductSheetsService from 'services/ProductSheetsService';

export default {
  data() {
    return {
      term: '',
      latestHits: []
    };
  },
  props: {
    /**
     * Define if the search input should be focused on load or not.
     */
    focusOnLoad: {
      type: Boolean,
      default: false
    },

    value: {
      type: String,
      required: false,
      default: null
    },

    activeIndustry: {
      type: String,
      required: false,
      default: null
    },

    activeFilters: {
      type: Object,
      required: false,
      default: null
    },

    onSearchPage: {
      type: Boolean,
      required: false,
      default: false
    },

    emitSelect: {
      type: Boolean,
      default: false
    },

    useProductSheetsService: {
      type: Boolean,
      default: false
    }
  },
  components: { VueSimpleSuggest },
  mounted() {
    if (this.focusOnLoad) this.$el.getElementsByTagName('input')[0].focus();

    if (this.value) this.setValueFromOutside(this.value);
  },
  watch: {
    value(newValue) {
      this.setValueFromOutside(newValue);
    }
  },
  computed: {
    ...mapGetters('user', ['token']),
    suggestionsDisabled() {
      return false;
    }
  },
  methods: {
    setValueFromOutside(value) {
      if (value && this.term !== value) this.term = value;
    },

    /**
     * Get suggestions for the autocomplete component.
     *
     * @param {String} term
     * @return {Promise<{}[]>}
     */
    suggest(term) {
      const cleanTerm = term && typeof term === 'string' ? term.trim() : null;

      return new Promise((resolve) => {
        if (this.suggestionsDisabled
          || (!cleanTerm || cleanTerm.length < 3)) resolve([]);
        else if (this.useProductSheetsService) {
          ProductSheetsService.suggest(cleanTerm, this.activeFilters, this.token).then((result) => {
            // Sort results by name asc
            let { hits } = result;

            if (!hits || !Array.isArray(hits)) {
              resolve([]);
              return;
            }

            hits = sortArray(hits, 'product_name');

            resolve(hits);
          });
        } else {
          PigmentService.suggest(cleanTerm, this.activeFilters, this.token).then((result) => {
            if (this.activeIndustry) {
              result.hits.forEach((pigment) => {
                pigment.link = `${pigment.link}?i=${this.activeIndustry}`;
              });
            }

            // Sort results by name asc
            let { hits } = result;

            if (!hits || !Array.isArray(hits)) {
              resolve([]);
              return;
            }

            hits = sortArray(hits, 'product_name');

            resolve(hits);
          });
        }
      });
    },

    /**
     * Autocomplete result selected.
     *
     * Redirect to the detail page per default, emit a "selected" event instead
     * if the emitSelect property is set to true.
     *
     */
    selected(suggestion) {
      if (!suggestion) return;

      if (this.emitSelect) {
        this.$emit('selected', suggestion);
        this.$nextTick(() => {
          this.$el.getElementsByTagName('input')[0].blur();
        });
      } else {
        location.href = `${location.origin}${suggestion.link}`;
      }
    },

    /**
     * Trigger the default search.
     *
     */
    search() {
      // TODO cancel suggest request if still pending?!
      this.$emit('search', this.term);
      this.$refs.suggestField.hideList();
    },

    /**
     * Listen for enter keydown.
     *
     * Trigger the search if no autocomplete result is "hovered".
     *
     * @return {boolean}
     */
    enter() {
      if (!this.$refs.suggestField.hovered) {
        this.search();
      }
    },
    hasFocus(elem) {
      return elem === document.activeElement && (elem.type || elem.href);
    },
    listShown() {
      // list shown listener, hide it immediately if the input has no focus
      if (!this.hasFocus(this.$el.getElementsByTagName('input')[0])) {
        // this.$refs.suggestField.hideList();
      }
    }
  }
};
</script>

<style lang="less">
  @import (reference) '~styles/includes/base';
  @import '~styles/includes/search-field.less';

  .ce-searchField {
    margin-bottom: @space-3; // TODO: @space-5 as soon as type switcher is back

    .vue-simple-suggest {
      position: relative;

      > ul {
        .list-reset;
        margin: 0;
      }

      .suggestions {
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        background: fade(@color-mono-100, 93);
        z-index: 1000;
        box-shadow: 0 3px 5px rgba(0,0,0,.16);

        .suggest-item {
          cursor: pointer;
          .noselect();
          .ms(1);

          &.hover, &.selected {
            background-color: @color-light;
            color: @color-mono-100;
          }
        }

        .suggest-item, .misc-item {
          padding: @space-2;
        }
      }
    }
  }
</style>
