<template>
  <modal
    :headline="$t('cookieModal.headline')"
    class="ce-cookieSettings"
    :closable="closable"
    @close="hideCookieModal">
    <div class="ce-modalContent">
      <div
        v-if="!detailsVisible"
        class="ce-cookieDescription">
        <p>
          {{ $t('cookieModal.description') }}
        </p>

        <i18n
          path="cookieModal.furtherInformation"
          tag="p">
          <a
            :href="links.dataProtectionPage"
            rel="noopener noreferrer">
            {{ $t('cookieModal.dataProtectionPage') }}
          </a>
        </i18n>

        <div class="mt4 flex flex-wrap mxn1">
          <button
            class="ce-btn ce-btn-grey m1"
            @click.prevent="showDetails">
            {{ $t('cookieModal.changeCta') }}
          </button>

          <button
            class="ce-btn ce-btn-light m1"
            @click.prevent="acceptAll">
            {{ $t('cookieModal.acceptCta') }}
          </button>
        </div>
      </div>

      <div
        v-if="detailsVisible"
        class="ce-cookieDetails">
        <div class="mb4 ms-1">
          <a
            href=""
            @click.prevent="hideDetails">
            <fa-icon icon="long-arrow-alt-left" />
            {{ $t('cookieModal.backCta') }}
          </a>
        </div>

        <div class="mb3">
          <checkbox
            id="cookies_mandatory"
            v-model="customSettings.mandatory"
            :disabled="true">
            <div v-html="$t('cookieModal.mandatoryCookiesDescription')" />
          </checkbox>
          <div class="ce-cookieSettings-furtherInfo">
            <div>
              <a @click.prevent="cookieDetails.mandatory = !cookieDetails.mandatory">{{ $t('cookieModal.showMandatoryCookiesCta') }}</a>
            </div>
            <div
              class="mt2"
              v-if="cookieDetails.mandatory">
              <table>
                <tr>
                  <th>Name</th>
                  <th>Lifetime</th>
                </tr>
                <tr>
                  <td>SECSESSID</td>
                  <td>session</td>
                </tr>
                <tr>
                  <td>ce-cookieSettings</td>
                  <td>30 days</td>
                </tr>
                <tr>
                  <td>ce-activeComparison</td>
                  <td>session</td>
                </tr>
                <tr>
                  <td>ce-comparisonIndustryHintSeen</td>
                  <td>18 months</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="mb3">
          <checkbox
            id="cookies_functional"
            v-model="customSettings.functional">
            <div v-html="$t('cookieModal.functionalCookiesDescription')" />
          </checkbox>
          <div class="ce-cookieSettings-furtherInfo">
            <div>
              <a @click.prevent="cookieDetails.functional = !cookieDetails.functional">{{ $t('cookieModal.showFunctionalCookiesCta') }}</a>
            </div>
            <div
              class="mt2"
              v-if="cookieDetails.functional">
              <table>
                <tr>
                  <th>Name</th>
                  <th>Lifetime</th>
                </tr>
                <tr>
                  <td>_pk_id</td>
                  <td>13 months</td>
                </tr>
                <tr>
                  <td>_pk_ref</td>
                  <td>6 months</td>
                </tr>
                <tr>
                  <td>_pk_ses</td>
                  <td>30 minutes</td>
                </tr>
                <tr>
                  <td>_pk_cvar</td>
                  <td>30 minutes</td>
                </tr>
                <tr>
                  <td>_pk_testcookie</td>
                  <td>temporary</td>
                </tr>
                <tr>
                  <td>MATOMO_SESSID</td>
                  <td>temporary</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="mb3">
          <checkbox
            id="cookies_marketing"
            v-model="customSettings.marketing">
            <div v-html="$t('cookieModal.marketingCookiesDescription')" />
          </checkbox>
          <div class="ce-cookieSettings-furtherInfo">
            <div>
              <a @click.prevent="cookieDetails.marketing = !cookieDetails.marketing">{{ $t('cookieModal.showMarketingCookiesCta') }}</a>
            </div>
            <div
              class="mt2"
              v-if="cookieDetails.marketing">
              <table>
                <tr>
                  <th>Name</th>
                  <th>Lifetime</th>
                </tr>
                <tr>
                  <td>uslk_e</td>
                  <td>1 day</td>
                </tr>
                <tr>
                  <td>uslk_s</td>
                  <td>session</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="mt4 flex flex-wrap mxn1">
          <button
            class="ce-btn ce-btn-grey m1"
            @click.prevent="saveCustomSettings">
            {{ $t('cookieModal.saveSettingsCta') }}
          </button>

          <button
            class="ce-btn ce-btn-light m1"
            @click.prevent="acceptAll">
            {{ $t('cookieModal.acceptAllCta') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Checkbox from 'components/Checkbox';

export default {
  components: { Checkbox },
  data() {
    return {
      detailsVisible: false,
      customSettings: {
        mandatory: true,
        functional: false,
        marketing: false
      },
      cookieDetails: {
        mandatory: false,
        functional: false,
        marketing: false
      }
    };
  },
  computed: {
    ...mapState('generic', ['cookieSettings', 'links']),
    hasSettings() {
      return this.cookieSettings && Object.keys(this.cookieSettings).length > 0;
    },
    closable() {
      return this.hasSettings;
    }
  },
  mounted() {
    if (this.hasSettings) {
      this.customSettings = this.$cloneObject(this.cookieSettings);
      this.detailsVisible = true;
    }
  },
  methods: {
    ...mapActions('generic', ['setCookieSettings', 'hideCookieModal']),
    showDetails() {
      this.detailsVisible = true;
    },
    hideDetails() {
      this.detailsVisible = false;
    },
    acceptAll() {
      this.setCookieSettings({
        mandatory: true,
        functional: true,
        marketing: true
      });
    },
    saveCustomSettings() {
      this.setCookieSettings(this.customSettings);
    }
  }
};
</script>

<style lang="less">
  @import (reference) '~styles/includes/base';

  .ce-modal.ce-cookieSettings {
    .ce-cookieDescription {
      line-height: 1.5;
      .ms(-1);
    }

    .ce-cookieDetails {
      .ce-checkbox {
        &.disabled {
          opacity: 1;

          .checkbox {
            opacity: 0.5;
          }
        }

        .checkbox {
          top: 0;
          margin-top: 5px;
        }

        label {
          font-weight: 400;
          .ms(-1);

          strong {
            font-weight: 600;
          }
        }
      }

      .ce-cookieSettings-furtherInfo {
        margin-top: @space-1;
        padding-left: 35px;
        .ms(-1);

        table {
          border-spacing: 0;
          width: 100%;
          margin: @space-3 0;
          border-collapse: collapse;

          th {
            border-top: 1px solid @color-mono-90;
            border-bottom: 2px solid @color-mono-90;
            padding: @space-2;
          }

          td {
            border-bottom: 1px solid @color-mono-92;
            padding: @space-2;
          }

          th {
            text-align: left;
          }
        }
      }
    }
  }
</style>
