var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ce-userMenu" }, [
    _c("nav", [
      _c(
        "ul",
        { staticClass: "ce-userMenu-navSection" },
        _vm._l(_vm.settingsRoutes, function (route) {
          return _c(
            "li",
            { key: route.path },
            [
              _vm.routerAvailable
                ? _c("router-link", { attrs: { to: { name: route.name } } }, [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [
                        route.meta && route.meta.icon
                          ? _c("fa-icon", { attrs: { icon: route.meta.icon } })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n\n          " + _vm._s(route.meta.title) + "\n        "
                    ),
                  ])
                : _c(
                    "a",
                    {
                      key: route.path,
                      attrs: { href: `/profile${route.path}` },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon" },
                        [
                          route.meta && route.meta.icon
                            ? _c("fa-icon", {
                                attrs: { icon: route.meta.icon },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(
                        "\n\n          " +
                          _vm._s(route.meta.title) +
                          "\n        "
                      ),
                    ]
                  ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.dataRoutes.length > 0
        ? _c(
            "ul",
            { staticClass: "ce-userMenu-navSection" },
            _vm._l(_vm.dataRoutes, function (route) {
              return _c(
                "li",
                { key: route.path },
                [
                  _vm.routerAvailable
                    ? _c(
                        "router-link",
                        { attrs: { to: { name: route.name } } },
                        [
                          _c(
                            "span",
                            { staticClass: "icon" },
                            [
                              route.meta && route.meta.icon
                                ? _c("fa-icon", {
                                    attrs: { icon: route.meta.icon },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(
                            "\n\n          " +
                              _vm._s(route.meta.title) +
                              "\n        "
                          ),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          key: route.path,
                          attrs: { href: `/profile${route.path}` },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon" },
                            [
                              route.meta && route.meta.icon
                                ? _c("fa-icon", {
                                    attrs: { icon: route.meta.icon },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(
                            "\n\n          " +
                              _vm._s(route.meta.title) +
                              "\n        "
                          ),
                        ]
                      ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.staticRoutes.length > 0
        ? _c(
            "ul",
            { staticClass: "ce-userMenu-navSection" },
            _vm._l(_vm.staticRoutes, function (route) {
              return _c("li", { key: route.path }, [
                _c("a", { key: route.path, attrs: { href: route.path } }, [
                  _c(
                    "span",
                    { staticClass: "icon" },
                    [
                      route.icon
                        ? _c("fa-icon", { attrs: { icon: route.icon } })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v("\n\n          " + _vm._s(route.title) + "\n        "),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ul", { staticClass: "ce-userProfileNavigation-actions" }, [
        _vm.user.hasBackendAccess
          ? _c("li", [
              _c("a", { attrs: { href: "/admin", target: "_blank" } }, [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("fa-icon", { attrs: { icon: ["fas", "server"] } })],
                  1
                ),
                _vm._v(
                  "\n\n          " +
                    _vm._s(_vm.$t("userProfile.actions.backendCta")) +
                    "\n        "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.user.canManageUsers
          ? _c("li", [
              _c(
                "a",
                { attrs: { href: _vm.userManagementLink, target: "_blank" } },
                [
                  _c(
                    "span",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: ["fas", "users-cog"] } })],
                    1
                  ),
                  _vm._v(
                    "\n\n          " +
                      _vm._s(_vm.$t("userProfile.actions.manageUsersCta")) +
                      "\n        "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: _vm.logoutUrl } }, [
            _c(
              "span",
              { staticClass: "icon" },
              [_c("fa-icon", { attrs: { icon: ["fas", "sign-out-alt"] } })],
              1
            ),
            _vm._v(
              "\n\n          " +
                _vm._s(_vm.$t("userProfile.actions.logoutCta")) +
                "\n        "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }