import { logger } from 'utils';
import * as types from './mutation-types';

export default {
  setUser({ commit, state }, user) {
    commit(types.SET_USER, user);
    state.userService.setUser(user);
    logger.setUser(user.userId);
  },
  updateUserToken({ commit }, token) {
    commit(types.UPDATE_USER_TOKEN, token);
  },
  async login({ dispatch, state }, { email, password }) {
    const loginResponse = await state.userService.login(email, password);

    if (loginResponse?.token) {
      dispatch('setUser', loginResponse);
    }

    return loginResponse;
  },
  async register({ state }, data) {
    return state.userService.register(data);
  },
  async updateUserInfo({ commit, state }, data) {
    if (state.activeUser) {
      await state.userService.update(data);
      commit(types.UPDATE_USER_INFO, data);
    }
  },
  async updateLocalUserInfo({ commit }, data) {
    commit(types.UPDATE_USER_INFO, data);
  },
  async changePassword({ state, commit }, newPassword) {
    await state.userService.update({
      password: newPassword
    });

    commit(types.UPDATE_USER_INFO, { requiredActions: null });
  },
  async requestValidation({ state, commit }, data) {
    await state.userService.requestValidation(data);

    if (state.activeUser) {
      commit(types.UPDATE_USER_INFO, { validationState: 'pending' });
    }
  },
  async changeEmail({ state, commit }, newEmail) {
    await state.userService.changeEmail(newEmail);

    if (state.activeUser) {
      commit(types.UPDATE_USER_INFO, { newEmail });
    }
  },
  showLogin({ commit, dispatch, state }) {
    if (state.registrationVisible) {
      dispatch('hideRegistration');
    }

    if (state.forgotPasswordVisible) {
      dispatch('hideForgotPassword');
    }

    commit(types.SHOW_LOGIN);
    dispatch('generic/trackEvent', { category: 'user', action: 'showLogin', name: false }, { root: true });
  },
  hideLogin({ commit }) {
    commit(types.HIDE_LOGIN);
  },
  showRegistration({ commit, state, dispatch }) {
    if (state.loginVisible) {
      dispatch('hideLogin');
    }

    if (state.forgotPasswordVisible) {
      dispatch('hideForgotPassword');
    }

    commit(types.SET_REGISTER_VISIBILITY, true);
  },
  hideRegistration({ commit }) {
    commit(types.SET_REGISTER_VISIBILITY, false);
  },
  showForgotPassword({ commit, state, dispatch }) {
    if (state.loginVisible) {
      dispatch('hideLogin');
    }

    if (state.registrationVisible) {
      dispatch('hideRegistration');
    }

    commit(types.SET_FORGOT_PASSWORD_VISIBILITY, true);
  },
  hideForgotPassword({ commit }) {
    commit(types.SET_FORGOT_PASSWORD_VISIBILITY, false);
  },
  showUserMenu({ commit }) {
    commit(types.SET_USER_MENU_VISIBILITY, true);
  },
  hideUserMenu({ commit }) {
    commit(types.SET_USER_MENU_VISIBILITY, false);
  },
  toggleUserMenu({ state, dispatch }) {
    if (state.userMenuVisible) {
      dispatch('hideUserMenu');
    } else {
      dispatch('showUserMenu');
    }
  },
  async getLogEntries({ state }, logType) {
    return state.userService.getLogEntries(logType);
  },
  async getSampleRequestForPRD({ state }, prd) {
    const entries = await state.userService.getLogEntries('sampleRequest', { prd, limit: 1 });

    return entries.length === 1 ? entries[0] : null;
  },
  async requestPasswordReset({ state }, email) {
    await state.userService.requestPasswordReset(email);
  },
  async resetPassword({ state }, data) {
    await state.userService.resetPassword(data.token, data.password);
  },
  async resendVerificationEmail({ state }, email) {
    await state.userService.resendVerificationEmail(email);
  },
};
