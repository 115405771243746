<template>
  <modal
    v-if="isVisible"
    :headline="$t('changedMySheetsHint.headline')"
    class="ce-changedMySheetsHint"
    :closable="true"
    @close="isOpen = false">
    <div class="ce-modalContent">
      <div class="mb4">
        {{ $t('changedMySheetsHint.message') }}
      </div>

      <div
        v-if="assetsManifest">
        <changed-product-row
          v-for="hit in changedMySheets.hits"
          :key="hit.id"
          :product="hit"
          :manifest="assetsManifest" />
      </div>

      <div class="mt4 flex flex-wrap justify-center mxn1">
        <a
          class="ce-btn ce-btn-light"
          :href="mySheetsLink">
          {{ $t('changedMySheetsHint.openCta') }}
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import ChangedProductRow from './ChangedProductRow.vue';

export default {
  components: { ChangedProductRow },
  data() {
    return {
      isOpen: true,
      assetsManifest: null
    };
  },
  watch: {
    isVisible(isVisible) {
      if (isVisible && !this.assetsManifest) {
        this.fetchAssetsManifest();
      }
    }
  },
  computed: {
    ...mapState('generic', ['changedMySheets', 'links', 'services']),
    ...mapState('user', ['activeUser']),
    updatesAvailable() {
      return this.changedMySheets
        && this.changedMySheets.total
        && this.changedMySheets.total > 0;
    },
    mySheetsLink() {
      return `${this.links.productSheetsPage}?tab=mysheets&min_doc_date=${this.activeUser.previousLogin}`;
    },
    isVisible() {
      return this.isOpen && this.updatesAvailable;
    }
  },
  methods: {
    async fetchAssetsManifest() {
      const response = (await axios.post(
        `${this.services.searchservice.url}/api/v1/manifest/search?type=document`,
        {
          source: ['code', 'title']
        },
        {
          headers: {
            Authorization: `Bearer ${this.activeUser.token}`
          }
        }
      )).data;

      this.assetsManifest = {};
      response.forEach((row) => {
        this.assetsManifest[row.code] = row.title;
      });
    }
  }
};
</script>

<style lang="less">
@import (reference) "~styles/includes/base";

.ce-changedMySheetsHint {
  h3 {
    margin: 0;
  }
}
</style>
