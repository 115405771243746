<template>
  <modal
    class="ce-userLogin"
    :headline="$t('login.modal.headline')"
    :description="$t('login.modal.description')"
    @close="close">
    <div class="ce-modalContent">
      <UserLogin />
    </div>
  </modal>
</template>
<script>
import UserLogin from './UserLogin.vue';

export default {
  components: { UserLogin },
  methods: {
    close() {
      this.$store.dispatch('user/hideLogin');
    }
  }
};
</script>

<style lang="less">
@import (reference) '~styles/includes/base';

</style>
