<template>
  <modal
    class="ce-forgotPassword"
    @close="close"
    :headline="$t('forgotPassword.modalHeadline')"
    :description="hasFinished ? null : $t('forgotPassword.modalDescription')">
    <div class="ce-modalContent ce-forgotPassword-content">
      <alert
        v-if="hasMessage"
        :message="message.content"
        :type="message.type"
        :closable="false" />

      <ValidationObserver
        v-if="!hasFinished"
        ref="validationObserver"
        tag="div">
        <validated-input
          name="email"
          :value="form.email"
          @input="onInput"
          :label="$t('forgotPassword.email')"
          autocapitalize="none"
          rules="required|email"
          mode="passive" />

        <div>
          <button
            class="ce-btn ce-btn-light ce-btn--fullWidth"
            :disabled="loading"
            @click.prevent="submit">
            <template v-if="loading">
              <fa-icon
                icon="spinner"
                spin />
            </template>
            <template v-else>
              {{ $t('forgotPassword.submitCta') }}
            </template>
          </button>
        </div>
      </ValidationObserver>

      <div class="mt2 ce-forgotPassword-subActions">
        <div class="ce-userLogin-register">
          <a
            href="#"
            @click.prevent="$store.dispatch('user/showLogin')">
            <fa-icon icon="long-arrow-alt-left" />
            {{ $t('forgotPassword.backToLoginCta') }}
          </a>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: null
      },
      loading: false,
      message: {
        type: 'success',
        content: null
      }
    };
  },
  computed: {
    hasMessage() {
      return this.message && this.message.content;
    },
    hasFinished() {
      return this.hasMessage && this.message.type === 'success';
    }
  },
  methods: {
    async submit() {
      const validationResult = await this.$refs.validationObserver.validate();
      if (!validationResult) return;
      this.loading = true;
      this.message = {};

      try {
        await this.$store.dispatch('user/requestPasswordReset', this.form.email);
      } catch (e) {
        // noop
      }

      this.message = {
        type: 'success',
        content: this.$t('forgotPassword.successMessage')
      };

      this.form.email = null;
      this.loading = false;
    },
    close() {
      this.$store.dispatch('user/hideForgotPassword');
    }
  }
};
</script>

<style lang="less">
@import (reference) '~styles/includes/base';

.ce-forgotPassword-content {
  > div {
    .mb3;
  }

  .ce-forgotPassword-subActions {
    .ce-pageHeader .ce-pageHeader-wrapper & {
      a {
        color: @color-light;
      }
    }
  }
}
</style>
