var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "ce-forgotPassword",
      attrs: {
        headline: _vm.$t("forgotPassword.modalHeadline"),
        description: _vm.hasFinished
          ? null
          : _vm.$t("forgotPassword.modalDescription"),
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "ce-modalContent ce-forgotPassword-content" },
        [
          _vm.hasMessage
            ? _c("alert", {
                attrs: {
                  message: _vm.message.content,
                  type: _vm.message.type,
                  closable: false,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasFinished
            ? _c(
                "ValidationObserver",
                { ref: "validationObserver", attrs: { tag: "div" } },
                [
                  _c("validated-input", {
                    attrs: {
                      name: "email",
                      value: _vm.form.email,
                      label: _vm.$t("forgotPassword.email"),
                      autocapitalize: "none",
                      rules: "required|email",
                      mode: "passive",
                    },
                    on: { input: _vm.onInput },
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "ce-btn ce-btn-light ce-btn--fullWidth",
                        attrs: { disabled: _vm.loading },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.loading
                          ? [
                              _c("fa-icon", {
                                attrs: { icon: "spinner", spin: "" },
                              }),
                            ]
                          : [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("forgotPassword.submitCta")) +
                                  "\n          "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mt2 ce-forgotPassword-subActions" }, [
            _c("div", { staticClass: "ce-userLogin-register" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$store.dispatch("user/showLogin")
                    },
                  },
                },
                [
                  _c("fa-icon", { attrs: { icon: "long-arrow-alt-left" } }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("forgotPassword.backToLoginCta")) +
                      "\n        "
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }